import { get } from 'lodash';

const extractUpcomingEventsData = prismicData => {
  const upcomingEventsSlices = get(prismicData, 'data.body', []).filter(
    slice => slice.slice_type === 'upcoming_events'
  );

  const upcomingEventsData = upcomingEventsSlices.map(upcomingEventsSlice => ({
    title: upcomingEventsSlice.primary.title,
    subtitle: upcomingEventsSlice.primary.subtitle,
    events: upcomingEventsSlice.items,
    isUpcoming: true
  }));

  return upcomingEventsData;
};

const extractPastEventsData = prismicData => {
  const pastEventsSlices = get(prismicData, 'data.body', []).filter(
    slice => slice.slice_type === 'past_events'
  );

  const pastEventsData = pastEventsSlices.map(pastEventsSlice => ({
    title: pastEventsSlice.primary.title,
    subtitle: pastEventsSlice.primary.subtitle,
    events: pastEventsSlice.items,
    playlistCoverImage: get(pastEventsSlice, 'items[0].event_link.document.data.cover_image', null)
  }));

  return pastEventsData;
};

const extractContentData = prismicData => {
  const contentSlice = get(prismicData, 'data.body', []).find(
    slice => slice.slice_type === 'content'
  );

  const contentData = contentSlice && {
    title: contentSlice.primary.title,
    subtitle: contentSlice.primary.subtitle,
    materials: contentSlice.items
  };
  return contentData;
};

export default prismicData => ({
  upcomingEvents: extractUpcomingEventsData(prismicData),
  pastEvents: extractPastEventsData(prismicData),
  content: extractContentData(prismicData)
});
