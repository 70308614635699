/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { useUserContext } from '~context/UserContext/UserSessionContext';

import Container from '~components/Container';
import LoadingSpinner from '~components/LoadingSpinner';
import Link from '~components/Link';
import { ANALYTICS_ANTI_CHURN_SCREEN_BUTTON_CLICKED } from '~hooks/useApi';
import request from '~utils/request';
import CounterOffersInterface from './CounterOffersInterface';
import CounterOffersAntiChurn from './CounterOffersAntiChurn';
import AntiChurnPayWhatYouCan from './AntiChurnPayWhatYouCan';

const useStyles = makeStyles(theme => ({
  dialogStyle: {
    [theme.breakpoints.down('xs')]: {
      '& > div > .MuiDialog-paperWidthSm': {
        maxWidth: 'calc(100% - 24px) '
      }
    }
  },
  indicator: {
    backgroundColor: '#2c7abf'
  },

  tabStyle: {
    color: '#4b5161',
    marginRight: '50px',
    padding: '0px 6px',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    minWidth: '80px',
    letterSpacing: '0em'
  },

  flexContainer: {
    paddingTop: '8px'
  },
  gridContainerStyle: {
    outline: 'none'
  },
  heading: {
    fontSize: '48px',
    fontWeight: '400',
    lineHeight: '56px',
    letterSpacing: '0em',
    marginTop: '70px',
    marginBottom: '36px',
    color: ' #4B5161'
  },
  breadcrumbs: {
    fontWeight: '500',
    marginBottom: '36px'
  }
}));

const CounterOfferContainer = () => {
  const [showCounterOffersInterface, setShowCounterOffersInterface] = useState(false);
  const [showPayWhatYouCan, setShowPayWhatYouCan] = useState(false);
  const classes = useStyles();
  const { session } = useUserContext();
  const { user, isUserStateLoading } = session;
  const isMobileUser =
    !!user.subscription_gateway_gplay_id || !!user.subscription_gateway_appstore_id;

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const handleProceedToCancel = async () => {
    await request(ANALYTICS_ANTI_CHURN_SCREEN_BUTTON_CLICKED({ button: 'Proceed To Cancel' }));

    setShowPayWhatYouCan(true);
  };

  const handleAfterPayWhatYouCan = async () => {
    await request(ANALYTICS_ANTI_CHURN_SCREEN_BUTTON_CLICKED({ button: 'Proceed To Cancel' }));

    setShowPayWhatYouCan(false);
    setShowCounterOffersInterface(true);
  };

  if (isUserStateLoading) {
    return <LoadingSpinner />;
  }

  if (showPayWhatYouCan) {
    return <AntiChurnPayWhatYouCan handleCancel={handleAfterPayWhatYouCan} />;
  }

  return showCounterOffersInterface ? (
    <>
      <Container center="vertical" background="#fff">
        <Typography variant="h2" className={classes.heading}>
          Cancel Subscription
        </Typography>
        <Typography variant="subtitle1" className={classes.breadcrumbs}>
          <Link to="/account">MANAGE YOUR ACCOUNT</Link>&nbsp;&nbsp;&gt;&nbsp;&nbsp;CANCEL
          SUBSCRIPTION
        </Typography>
      </Container>

      <Container
        center="horizontal"
        size={100}
        background="#F7F4EF"
        style={{ marginTop: '-1px', paddingBottom: '100px' }}
      >
        <Box
          bgcolor="white"
          marginTop={9}
          p={isXs ? 2 : 5}
          width="100%"
          style={{ position: 'relative' }}
        >
          <CounterOffersInterface
            isPayPalUser={!!user.subscription_gateway_paypal_id}
            isMobileUser={isMobileUser}
          />
        </Box>
      </Container>
    </>
  ) : (
    <CounterOffersAntiChurn handleProceedToCancel={handleProceedToCancel} />
  );
};

export default CounterOfferContainer;
