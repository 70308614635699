/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import { trackCancellationIntent, trackPageView } from '~utils/analytics';
import useApi, { UPDATE_STRIPE_CARD, CANCEL_STRIPE, CANCEL_PAYPAL } from '~hooks/useApi';
import useEducation from '~hooks/useEducation';

import { pushDataLayerEvent } from '~utils/data-layer';
import Container from '~components/Container';
import Dialog from '~components/Dialog';
import OverviewLayout from '../OverviewLayout';
import EducatorLayout from '../EducatorLayout';
import ProducersCircleLayout from '../ProducersCircleLayout';
import ViewHistoryLayout from '../ViewHistoryLayout';
import { calculatePopup } from '../../account.utils';

const TabPanel = ({ ...props }) => {
  const { index, value, children, ...rest } = props;

  if (value !== index) {
    return null;
  }

  return (
    <div
      role="tabpanel"
      id={`nav-tabpanel-${index}`}
      style={{ marginTop: '80px', width: '100%' }}
      {...rest}
    >
      {children}
    </div>
  );
};

TabPanel.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100%'
  },
  dialogStyle: {
    [theme.breakpoints.down('xs')]: {
      '& > div > .MuiDialog-paperWidthSm': {
        maxWidth: 'calc(100% - 24px) '
      }
    }
  },
  indicator: {
    backgroundColor: '#2c7abf'
  },

  tabStyle: {
    color: '#4b5161',
    marginRight: '50px',
    padding: '0px 6px',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    minWidth: '80px',
    letterSpacing: '0em'
  },

  flexContainer: {
    paddingTop: '8px'
  },
  gridContainerStyle: {
    outline: 'none'
  },
  heading: {
    fontSize: '48px',
    fontWeight: '400',
    lineHeight: '56px',
    letterSpacing: '0em',
    marginTop: '70px',
    marginBottom: '60px',
    color: ' #4B5161'
  },
  container: {
    marginTop: '-1px',
    paddingBottom: '100px'
  }
}));

const AccountContainer = ({ location }) => {
  const classes = useStyles();
  const { session } = useUserContext();
  const {
    isSubscriber,
    user,
    isUserStateLoading,
    isEducator,
    isProducer,
    isAdmin,
    isPrivilegedMember
  } = session;
  const { state, addStudent } = useEducation();
  const { isLoading, data, isError, error } = state;
  const { search } = location; // query string from the url
  const parsedSearchQuery = search && queryString.parse(search);

  const { tab, enableHistory } = search && parsedSearchQuery;

  const [value, setValue] = useState(Number(tab) || 0);
  const [isOpen, setIsOpen] = useState(false);

  const [popupId, setPopupId] = useState();

  const handlePopupOpenClick = id => {
    setIsOpen(true);
    setPopupId(id);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // CC Update
  const [cardState, cardUpdate] = useApi();

  const onStripeSuccess = stripeResponse => {
    pushDataLayerEvent('account_update_credit_card');
    const reqParams = UPDATE_STRIPE_CARD({ newSourceToken: stripeResponse.id });
    return cardUpdate(reqParams);
  };

  const isCancelationPopup = popupId === 7;

  // Cancel Subscription
  const [stripeState, stripeCancel] = useApi();
  const [paypalState, paypalCancel] = useApi();

  const handleCancelSubscription = () => {
    handleClose();
    trackCancellationIntent('confirmed');
    pushDataLayerEvent('subscription_cancel_confirm');

    if (user.subscription_gateway_stripe_id) {
      const stripeCancelParams = CANCEL_STRIPE();
      stripeCancel(stripeCancelParams);
    }

    if (user.subscription_gateway_paypal_id) {
      const paypalCancelParams = CANCEL_PAYPAL();
      paypalCancel(paypalCancelParams);
    }
  };

  const handleProducersCircleTabClick = () => {
    trackPageView();
  };

  if (isUserStateLoading) {
    return null;
  }

  return (
    <>
      <Container center="vertical" background="#fff">
        <Typography variant="h2" className={classes.heading}>
          {value === 2 ? 'Patrons Circle Exclusive Content' : 'Manage Your Account'}
        </Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          classes={{
            root: classes.root,
            indicator: classes.indicator,
            flexContainer: classes.flexContainer
          }}
        >
          <Tab label="Overview" value={0} className={classes.tabStyle} disableRipple />
          <Tab label="Educator" value={1} className={classes.tabStyle} disableRipple />
          {isProducer && (
            <Tab
              label="Patrons Circle"
              value={2}
              className={classes.tabStyle}
              onClick={handleProducersCircleTabClick}
              disableRipple
            />
          )}
          {enableHistory && (
            <Tab label="View History" value={3} className={classes.tabStyle} disableRipple />
          )}
        </Tabs>
      </Container>
      <Container center="horizontal" size={100} background="#F7F4EF" className={classes.container}>
        <TabPanel index={0} value={value}>
          <OverviewLayout
            setIsOpen={setIsOpen}
            user={user}
            isSubscriber={isSubscriber}
            isPrivilegedMember={isPrivilegedMember}
            isProducer={isProducer}
            isEducator={isEducator}
            isAdmin={isAdmin}
            onStripeSuccess={onStripeSuccess}
            handlePopupOpenClick={handlePopupOpenClick}
          />
        </TabPanel>
        <TabPanel index={1} value={value}>
          <EducatorLayout
            setIsOpen={setIsOpen}
            user={user}
            handlePopupOpenClick={handlePopupOpenClick}
            isEducator={isEducator}
            addStudentState={state}
            addStudent={addStudent}
          />
        </TabPanel>
        <TabPanel index={2} value={value}>
          <ProducersCircleLayout isUserStateLoading={isUserStateLoading} isProducer={isProducer} />
        </TabPanel>
        {enableHistory && (
          <TabPanel index={3} value={value}>
            <ViewHistoryLayout isUserStateLoading={isUserStateLoading} />
          </TabPanel>
        )}
      </Container>
      <Dialog
        isOpen={isOpen}
        className={classes.dialogStyle}
        id="banner_exit_intent"
        backdrop="rgba(0, 0, 0, 0.6)"
        disableEscapeKeyDown={false}
        disableBackdropClick={false}
        onClose={handleClose}
      >
        <Box bgcolor={isCancelationPopup ? 'transparent' : '#fff'} p={isCancelationPopup ? 0 : 6}>
          {calculatePopup(
            popupId,
            handleClose,
            addStudent,
            user,
            isLoading,
            data,
            isError,
            error,
            handleCancelSubscription
          )}
        </Box>
      </Dialog>
    </>
  );
};

AccountContainer.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

export default AccountContainer;
