import React from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/styles/makeStyles';
import useTheme from '@material-ui/styles/useTheme';

import { ANALYTICS_ANTI_CHURN_SCREEN_BUTTON_CLICKED } from '~hooks/useApi';
import request from '~utils/request';
import AntiChurnHeroImage from '~images/anti-churn/anti-churn-hero.png';
import AntiChurnHeroMobileImage from '~images/anti-churn/anti-churn-hero-mobile.png';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 700,
    fontSize: '33px',
    maxWidth: '310px',
    textAlign: 'center',
    margin: '-70px auto 32px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0'
    }
  },

  subtitle: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '28px',
    maxWidth: '520px',
    textAlign: 'center',
    margin: '30px auto',
    marginTop: '40px'
  },

  list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '16px 0'
  },

  media: {
    maxWidth: '100%',
    display: 'flex'
  },

  button: {
    backgroundColor: '#0869C1',
    color: '#fff',
    padding: '8px 24px',
    display: 'block',
    fontSize: '20px',
    margin: '30px auto 15px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#0869C1c8'
    }
  },

  cancelButton: {
    color: '#0869C1',
    display: 'block',
    margin: '0 auto',
    fontSize: '18px',
    textTransform: 'none',
    textDecoration: 'underline',
    '&:hover': {
      backgroundColor: '#fff'
    }
  }
}));

function CounterOffersAntiChurn({ handleProceedToCancel }) {
  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const navigateToHome = async () => {
    await request(ANALYTICS_ANTI_CHURN_SCREEN_BUTTON_CLICKED({ button: 'Continue Support' }));

    navigate('/');
  };

  return (
    <Box py={isSm ? 0 : 5} bgcolor="#f2f2f2">
      <Container disableGutters maxWidth="md" style={{ background: '#fff', borderRadius: '11px' }}>
        <img
          className={classes.media}
          src={isSm ? AntiChurnHeroMobileImage : AntiChurnHeroImage}
          alt="Anti Churn Hero"
        />

        <Box fontSize={18} textAlign="center" display="block" pb={4}>
          <Typography className={classes.title}>Before you go - here’s a little secret.</Typography>

          <Typography variant="inherit" component="p" gutterBottom>
            Aleph Beta isn’t funded by multi- million dollar gifts from philanthropists.
          </Typography>

          <Typography className={classes.subtitle}>
            We’re a crowd funded platform and exist because of subscribers just like you!
          </Typography>

          <Typography variant="inherit" component="p">
            We make the Torah come alive - and we rely on your support to do it!
          </Typography>
          <Typography variant="inherit" component="p">
            Your (tax-deductible) subscription supports:
          </Typography>

          <ul className={classes.list}>
            <li>Torah Scholars</li>
            <li>Writers and Editors</li>
            <li>Graphic Designers</li>
            <li>Animators</li>
          </ul>

          <Typography variant="inherit" component="p">
            All whom are dedicated to making and spreading Torah content
          </Typography>

          <Button className={classes.button} onClick={navigateToHome}>
            I&apos;ll continue my support
          </Button>

          <Button className={classes.cancelButton} onClick={handleProceedToCancel}>
            Proceed to cancel
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default CounterOffersAntiChurn;

CounterOffersAntiChurn.propTypes = {
  handleProceedToCancel: PropTypes.func.isRequired
};
