import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Divider from '~src/components/Divider';
import Link from '~src/components/Link';
import Header from './elements/Header';
import Subtitle from './elements/Subtitle';
import LinkButton from './elements/LinkButton';
import Button from './elements/Button';

const content = [
  {
    url: '/playlist/oral-and-written-law',
    thumbUrl:
      'https://images.prismic.io/aleph-beta/b94c75e8-a7be-4efe-895d-9b115877a558_written-oral-law.jpg'
  },
  {
    url: '/playlist/is-vegetarianism-a-jewish-value',
    thumbUrl:
      'https://images.prismic.io/aleph-beta/94ad8540-c7d8-4fbd-b8bc-f0e420441617_vegetarianism.jpg'
  },
  {
    url: '/playlist/who-would-want-to-worship-avodah-zarah',
    thumbUrl:
      'https://images.prismic.io/aleph-beta/ec9eea6b-47db-438a-b52d-a577e92bd587_avodah-zarah.jpg'
  },
  {
    url: '/playlist/what-is-the-meaning-of-life',
    thumbUrl:
      'https://images.prismic.io/aleph-beta/56ba71c2-1992-49b0-928e-da98ac39b2be_what-is-life-meaning-bible-video.jpg'
  },
  {
    url: '/playlist/hidden-structure-of-ten-commandments',
    thumbUrl:
      'https://images.prismic.io/aleph-beta/6a686c828eacba754b6339bfb1962ded752ddb76_hidden-structure-of-ten-commandments_1526188267913.jpg'
  },
  {
    url: '/playlist/shacharit-morning-prayers',
    thumbUrl:
      'https://images.prismic.io/aleph-beta/e804893b-3d8e-4d0f-9fca-e166185de3bf_six-days-series-2020.jpg'
  }
];

const CounterOfferContent = ({ handleAcceptOffer, handleDeclineOffer }) => (
  <>
    <Header>Our library is always expanding</Header>

    <Subtitle>
      We’re sorry you haven’t found the content you were looking for, but did you know we have over
      300 lectures and not just on parsha and holidays?{' '}
      <strong>We&apos;d love to offer you 3 months at 50% off</strong> so you can continue to
      explore Aleph Beta. We suggest starting with our Big Ideas series:
    </Subtitle>

    <Grid container direction="row" justify="center" alignItems="center" spacing={4}>
      {content.map(({ url, thumbUrl }) => (
        <Grid key={url} item xs={12} md={6} lg={4}>
          <Link to={url} style={{ width: '100%' }}>
            <img src={thumbUrl} style={{ maxWidth: '100%', display: 'flex' }} alt={url} />
          </Link>
        </Grid>
      ))}
    </Grid>

    <Divider />

    <Button onClick={handleAcceptOffer}>Redeem Offer</Button>
    <LinkButton onClick={handleDeclineOffer}>Thanks, but I’d still like to cancel</LinkButton>
  </>
);

CounterOfferContent.propTypes = {
  handleAcceptOffer: PropTypes.func.isRequired,
  handleDeclineOffer: PropTypes.func.isRequired
};

export default CounterOfferContent;
