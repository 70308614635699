import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import makeStyles from '@material-ui/core/styles/makeStyles';
import AccountBillingPopupImage from '~images/account/billing-popup-image.png';

import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import Link from '~components/Link';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '0',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    maxHeight: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '20px'
    }
  },
  dialogContentStyle: {
    padding: '0',
    maxHeight: '100%'
  },
  media: {
    display: 'flex',
    maxWidth: '100%'
  }
}));

const CancelationPopup = ({ handleCancelSubscription, handleClose }) => {
  const classes = useStyles();

  return (
    <>
      <img
        src={AccountBillingPopupImage}
        alt="Cancel subscription popup"
        className={classes.media}
      />
      <Box bgcolor="#fff" p={3}>
        <Typography variant="h4" align="center" gutterBottom>
          Before you cancel!
        </Typography>

        <Typography variant="subtitle1" align="center" gutterBottom>
          Did you know that Aleph Beta is a non-profit organization that helps over 75,000 people
          learn Torah daily?
        </Typography>

        <Typography variant="subtitle1" align="center" gutterBottom>
          Our videos have been viewed over 1 million times, with over 11 million minutes watched.
          Your contributions (which are tax deductible) employ a team of educators, producers, and
          animators to create innovate Torah videos, with the goal of helping a modern audience
          build a meaningful connection to the Torah and its Author.
        </Typography>

        <Typography variant="subtitle1" align="center" gutterBottom>
          Please reconsider your impact and remember that your donations help thousands learn Torah
          and build a closer relationship to God.
        </Typography>

        <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
          <Grid item xs={12} md={6}>
            <ButtonSubmit
              type="button"
              variant="text"
              color="secondary"
              onClick={handleCancelSubscription}
            >
              Cancel Donation
            </ButtonSubmit>
          </Grid>

          <Grid item xs={12} md={6}>
            <ButtonSubmit type="button" onClick={handleClose}>
              Continue Donation
            </ButtonSubmit>
          </Grid>
        </Grid>

        <Link to="/subscribe">
          <Typography variant="subtitle1" align="center" gutterBottom>
            Change donation amount
          </Typography>
        </Link>
      </Box>
    </>
  );
};

CancelationPopup.propTypes = {
  handleCancelSubscription: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default CancelationPopup;
