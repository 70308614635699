import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import { useForm } from 'react-hook-form';

import Button from '@material-ui/core/Button';
import useAccount from '~hooks/useAccount';
import { useUserContext } from '~context/UserContext/UserSessionContext';

import MessageBox from '~components/forms/MessageBox';
import TextInput from '~components/form-inputs/TextInput';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import PopupCloseButton from '../shared/PopupCloseButton';
import PopupHeading from '../shared/PopupHeading';

const ProfileForm = ({ onClose }) => {
  const { register, handleSubmit } = useForm();

  const { state, updateProfile } = useAccount();
  const { isLoading, isError, data, error } = state;

  const { session } = useUserContext();
  const { user } = session;

  const {
    meta_first_name,
    meta_last_name,
    meta_phone,
    meta_address,
    meta_city,
    meta_zip,
    meta_country
  } = user;

  const onSubmit = formData => {
    updateProfile(formData);
  };

  if (data) {
    onClose();
  }
  return (
    <div style={{ position: 'relative' }}>
      <PopupHeading title="Update Personal Info" />
      <form onSubmit={handleSubmit(onSubmit)}>
        {isError && <MessageBox text={error} isError />}

        {!data && (
          <>
            <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="First Name"
                  name="meta_first_name"
                  autoComplete="given-name"
                  defaultValue={meta_first_name && meta_first_name}
                  inputRef={register}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Last Name"
                  name="meta_last_name"
                  autoComplete="family-name"
                  defaultValue={meta_last_name && meta_last_name}
                  inputRef={register}
                  disabled={isLoading}
                />
              </Grid>
            </Grid>

            <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Phone number"
                  name="meta_phone"
                  type="tel"
                  autoComplete="tel"
                  defaultValue={meta_phone && meta_phone}
                  inputRef={register}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Address"
                  name="meta_address"
                  autoComplete="street-address"
                  defaultValue={meta_address && meta_address}
                  inputRef={register}
                  disabled={isLoading}
                />
              </Grid>
            </Grid>

            <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
              <Grid item xs={12} md={4}>
                <TextInput
                  label="City"
                  name="meta_city"
                  autoComplete="address-level2"
                  defaultValue={meta_city && meta_city}
                  inputRef={register}
                  disabled={isLoading}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextInput
                  label="ZIP"
                  name="meta_zip"
                  autoComplete="postal-code"
                  defaultValue={meta_zip && meta_zip}
                  inputRef={register}
                  disabled={isLoading}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextInput
                  label="Country"
                  name="meta_country"
                  autoComplete="country"
                  defaultValue={meta_country && meta_country}
                  inputRef={register}
                  disabled={isLoading}
                />
              </Grid>
            </Grid>

            <ButtonSubmit fullWidth disabled={isLoading} style={{ boxShadow: 'unset' }}>
              Save Changes
            </ButtonSubmit>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={onClose}
              disabled={isLoading}
              style={{ boxShadow: 'unset' }}
            >
              Cancel
            </Button>
          </>
        )}
      </form>
      <PopupCloseButton handleClose={onClose} />
    </div>
  );
};

ProfileForm.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ProfileForm;
