import React from 'react';
import PropTypes from 'prop-types';

import { localeDate } from '~utils/dates';

const formatMoneyAmount = price =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price);

/**
 * I see no other way to include this SVG image to the PDF being generated.
 * PDFMaker doesn't support referencing SVG content via their "VFS" feature or image maps (https://pdfmake.github.io/docs/0.1/document-definition-object/svgs/)
 * I can't forcefully import an SVG file as a text string (it imports as a file path, absolutely useless)
 * And I can't just use `<img src={AlephBetaLogoFilePath} />` as PDFMaker doesn't have access to website URLs.
 * For reference, this is the contents of the `frontend/src/assets/images/account/payment-history-header-logo.svg`
 */
const AlephBetaLogo = () => (
  <svg width="169" height="35" viewBox="0 0 169 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M44.3515 17.9368L41.6224 7.75766L38.7308 17.9368H44.3515ZM39.0557 2.66807C39.5105 2.60282 39.9654 2.53757 40.3878 2.50495C40.8101 2.47232 41.265 2.47232 41.7848 2.47232C42.3047 2.47232 42.6621 2.47232 43.0844 2.50495C43.5068 2.53757 43.9616 2.60282 44.4815 2.66807L51.9216 26.8762C51.0444 27.0067 50.1672 27.072 49.29 27.072C48.4777 27.072 47.6655 27.0067 46.8532 26.8762L45.5212 22.015H37.6261L36.2616 26.8762C35.5143 27.0067 34.7345 27.072 33.9873 27.072C33.175 27.072 32.3628 27.0067 31.5505 26.8762L39.0557 2.66807Z"
      fill="#4B5161"
    />
    <path
      d="M51.8242 2.244C53.3837 1.983 54.9432 1.983 56.5028 2.244V19.927C56.5028 20.4817 56.5352 21.0689 56.6327 21.6236C56.6977 21.9825 56.7952 22.3087 56.9901 22.635C57.1525 22.8633 57.38 23.0265 57.6399 23.1243C57.9648 23.2222 58.2897 23.2548 58.6471 23.2548C59.0694 23.2548 59.4593 23.1896 59.8492 23.1243C60.0766 24.0052 60.2066 24.9187 60.2066 25.8323V26.3216C60.2066 26.4848 60.1741 26.6479 60.1416 26.811C59.7192 26.9415 59.2969 27.0068 58.8745 27.0394C58.3872 27.072 57.8998 27.1047 57.5099 27.1047C56.0154 27.1699 54.5534 26.6805 53.4162 25.6691C52.3765 24.723 51.8892 23.157 51.8892 21.0037L51.8242 2.244Z"
      fill="#4B5161"
    />
    <path
      d="M71.1556 16.3056C71.1556 15.3594 70.8632 14.4459 70.3109 13.6955C69.7585 13.0104 68.9138 12.6515 68.0366 12.7167C67.0944 12.6515 66.1847 13.0104 65.5349 13.6629C64.9176 14.4133 64.5602 15.3594 64.4952 16.3382L71.1556 16.3056ZM64.4627 19.6334C64.4302 20.7753 64.95 21.8519 65.8598 22.5697C66.8344 23.2222 68.0041 23.5158 69.1737 23.4832C70.7332 23.4832 72.2602 23.1895 73.7223 22.6349C73.9822 23.1569 74.1771 23.6789 74.3396 24.2662C74.5345 24.9187 74.632 25.6038 74.632 26.289C72.7801 26.9741 70.7982 27.333 68.8163 27.3004C67.4193 27.333 66.0547 27.1046 64.7226 26.6152C63.6504 26.2237 62.7082 25.5712 61.9285 24.7556C61.1812 23.9399 60.6289 22.9612 60.3365 21.8845C59.9791 20.71 59.8167 19.5029 59.8167 18.2631C59.8167 17.0559 59.9791 15.8162 60.3365 14.6416C60.6614 13.565 61.2137 12.5862 61.9285 11.7053C62.6433 10.8571 63.5205 10.2046 64.4952 9.74781C65.6323 9.25843 66.8344 8.99743 68.1016 9.03005C69.2062 8.99743 70.2784 9.22581 71.2856 9.64994C72.1953 10.0741 73.0075 10.6613 73.6573 11.4117C74.3071 12.1947 74.8269 13.0756 75.1193 14.0544C75.4442 15.131 75.6067 16.2077 75.6067 17.3496C75.6067 17.7737 75.6067 18.1978 75.5742 18.622C75.5417 19.0461 75.5092 19.3724 75.4442 19.6334H64.4627Z"
      fill="#4B5161"
    />
    <path
      d="M81.2599 23.1569C81.9421 23.3527 82.6244 23.4506 83.3067 23.4179C84.5088 23.4832 85.6785 22.9938 86.4582 22.0803C87.173 21.1668 87.5629 19.8291 87.5629 18.0021C87.5629 17.3496 87.4979 16.6971 87.4004 16.0772C87.303 15.5226 87.108 15.0006 86.8481 14.5438C86.5882 14.1197 86.2633 13.7934 85.8409 13.5324C84.9312 13.043 83.859 13.043 82.9168 13.4998C82.527 13.6955 82.2021 14.0218 81.9746 14.3807C81.7147 14.7722 81.5523 15.2289 81.4548 15.6857C81.3573 16.2077 81.2923 16.7297 81.2923 17.2843L81.2599 23.1569ZM76.5488 9.42159C76.8412 9.35634 77.1661 9.32372 77.4585 9.29109C77.751 9.29109 78.0758 9.25847 78.4657 9.25847C79.1155 9.25847 79.7328 9.32372 80.3501 9.45422C80.4476 9.61735 80.4801 9.78048 80.5451 9.9436C80.6101 10.172 80.6425 10.3677 80.7075 10.6287C80.7725 10.8897 80.805 11.1181 80.8375 11.3465C80.87 11.5749 80.9025 11.7706 80.9025 11.9664C81.1299 11.5749 81.3573 11.2486 81.6172 10.8897C81.9097 10.5309 82.267 10.2046 82.6569 9.97623C83.0793 9.6826 83.5341 9.48685 84.0215 9.32372C84.5738 9.16059 85.1261 9.06271 85.711 9.06271C86.6207 9.06271 87.5304 9.22584 88.3751 9.58472C89.2199 9.9436 89.9671 10.4982 90.5519 11.216C91.2017 12.0316 91.6891 12.9778 91.9815 13.9565C92.3389 15.2289 92.5338 16.534 92.5013 17.8716C92.5338 19.2093 92.3389 20.5469 91.884 21.7867C91.1043 24.0378 89.3823 25.7996 87.1405 26.6152C85.8734 27.072 84.5738 27.3004 83.2417 27.2678C82.8519 27.2678 82.4945 27.2678 82.1371 27.2351C81.7797 27.2025 81.4873 27.1699 81.2274 27.1046V34.8369C80.805 34.9021 80.4151 34.9348 80.0252 34.9674C79.6354 35 79.2455 35 78.8881 35C78.5307 35 78.1408 35 77.751 34.9674C77.3611 34.9348 76.9712 34.9021 76.5488 34.8369V9.42159Z"
      fill="#4B5161"
    />
    <path
      d="M94.1585 2.24397C94.5484 2.17872 94.9382 2.14609 95.3281 2.11347C95.718 2.11347 96.1079 2.08084 96.4978 2.08084C96.8551 2.08084 97.2775 2.08084 97.6674 2.11347C98.0898 2.14609 98.4796 2.17872 98.8695 2.24397V11.5096C99.032 11.3139 99.2269 11.0855 99.4868 10.7919C99.7792 10.4982 100.072 10.2046 100.429 9.97623C101.371 9.35634 102.476 9.03008 103.581 9.03008C105.465 9.03008 106.927 9.55209 107.902 10.6287C108.876 11.7054 109.364 13.4019 109.364 15.7183V26.9089C108.974 26.9741 108.551 27.0068 108.162 27.0394C107.772 27.0394 107.382 27.0394 106.992 27.0394C106.602 27.0394 106.212 27.0394 105.822 27.0394C105.4 27.0068 105.01 26.9741 104.62 26.9089V17.0233C104.685 16.0446 104.49 15.0984 104.068 14.2175C103.678 13.565 102.931 13.1735 102.151 13.2388C101.761 13.2388 101.371 13.304 100.981 13.4019C100.559 13.5324 100.202 13.7608 99.9092 14.0544C99.5518 14.4459 99.2919 14.9027 99.0969 15.4247C98.8695 16.1751 98.772 16.9255 98.772 17.7085V26.8763C98.3822 26.9415 97.9598 26.9741 97.5699 27.0068C97.18 27.0068 96.7902 27.0068 96.4003 27.0068C96.0104 27.0068 95.653 27.0068 95.2631 27.0068C94.8733 26.9741 94.4834 26.9415 94.0935 26.8763V2.24397"
      fill="#4B5161"
    />
    <path
      d="M115.602 24.2009C115.927 24.2335 116.317 24.2662 116.772 24.2988C117.259 24.3314 117.714 24.3314 118.169 24.3314C118.916 24.3314 119.663 24.2662 120.411 24.1357C121.06 24.0052 121.678 23.7768 122.23 23.4505C122.75 23.1243 123.172 22.6675 123.497 22.1455C123.822 21.493 123.984 20.7752 123.952 20.0248C123.984 19.3071 123.822 18.5893 123.53 17.9042C123.27 17.3496 122.847 16.8928 122.36 16.5339C121.84 16.175 121.223 15.914 120.573 15.7835C119.858 15.6204 119.111 15.5551 118.364 15.5551H115.57L115.602 24.2009ZM118.039 12.7494C119.273 12.8472 120.476 12.4883 121.483 11.738C122.327 10.9876 122.782 9.8783 122.685 8.76903C122.75 7.65977 122.263 6.58312 121.385 5.89799C120.346 5.21285 119.143 4.88659 117.909 4.95185C117.486 4.95185 117.032 4.95184 116.642 4.98447C116.252 5.0171 115.895 5.04972 115.602 5.08235V12.7494H118.039ZM112.288 2.63543C113.068 2.50493 113.815 2.40705 114.595 2.37443C115.602 2.30918 116.577 2.27655 117.616 2.27655C118.786 2.27655 119.923 2.37443 121.06 2.60281C122.003 2.79856 122.912 3.15744 123.725 3.71207C124.439 4.20146 125.057 4.88659 125.447 5.66961C125.869 6.58312 126.096 7.56189 126.064 8.57328C126.064 9.84568 125.674 11.0854 124.894 12.0968C124.569 12.5536 124.147 12.9451 123.692 13.2387C123.237 13.5324 122.782 13.7607 122.263 13.9239C122.912 13.9891 123.53 14.1522 124.147 14.4133C124.797 14.6743 125.349 15.0658 125.836 15.5551C126.356 16.0772 126.779 16.7297 127.039 17.4148C127.396 18.2957 127.558 19.2092 127.526 20.1554C127.558 21.2972 127.298 22.4391 126.779 23.4832C126.291 24.364 125.576 25.1471 124.699 25.7017C123.757 26.289 122.717 26.7131 121.613 26.9088C120.378 27.1698 119.079 27.2677 117.811 27.2677C116.967 27.2677 116.057 27.2351 115.05 27.1372C114.043 27.0393 113.133 26.9741 112.223 26.8436L112.288 2.63543Z"
      fill="#4B5161"
    />
    <path
      d="M140.554 16.5666C140.554 15.9467 140.489 15.3595 140.294 14.7722C140.132 14.2176 139.904 13.7282 139.579 13.2714C139.254 12.8473 138.832 12.4884 138.377 12.26C137.825 11.999 137.208 11.8359 136.59 11.8685C135.421 11.7706 134.283 12.2274 133.504 13.0757C132.724 14.0544 132.269 15.2942 132.204 16.534L140.554 16.5666ZM132.139 19.0461C132.172 20.9384 132.659 22.3087 133.569 23.1896C134.478 24.0705 135.843 24.4946 137.63 24.4946C139.157 24.4946 140.684 24.2336 142.081 23.679C142.276 24.0705 142.406 24.462 142.503 24.8861C142.601 25.3429 142.666 25.8323 142.698 26.3216C141.919 26.6479 141.139 26.8763 140.294 27.0394C139.352 27.2025 138.377 27.2678 137.402 27.2678C136.038 27.3004 134.706 27.072 133.439 26.6153C132.399 26.2238 131.457 25.6039 130.71 24.7882C129.995 23.9726 129.442 22.9938 129.118 21.9498C128.793 20.7753 128.598 19.5682 128.63 18.361C128.63 17.1539 128.793 15.9467 129.118 14.7722C129.41 13.7282 129.897 12.7168 130.58 11.8685C131.262 11.0203 132.107 10.3677 133.081 9.91099C134.186 9.42161 135.421 9.1606 136.623 9.19323C137.695 9.1606 138.735 9.38898 139.709 9.81312C140.554 10.2046 141.334 10.7593 141.919 11.4444C142.536 12.1948 142.991 13.043 143.251 13.9566C143.576 15.0006 143.738 16.0772 143.706 17.1539C143.706 17.4801 143.706 17.8064 143.706 18.1653C143.706 18.5241 143.673 18.8178 143.641 19.0461H132.139Z"
      fill="#4B5161"
    />
    <path
      d="M146.077 12.26H143.478L143.348 11.7706L149.034 5.31076H149.294V9.58471H153.582C153.68 10.0088 153.712 10.433 153.712 10.8571C153.712 11.1181 153.712 11.3465 153.68 11.5749C153.647 11.8032 153.615 12.0316 153.582 12.26H149.294V20.1554C149.294 20.9058 149.326 21.6562 149.424 22.4065C149.456 22.8633 149.618 23.2874 149.813 23.7116C149.976 24.0052 150.268 24.2336 150.593 24.3315C150.983 24.462 151.405 24.4946 151.795 24.4946C152.153 24.4946 152.51 24.462 152.867 24.3967C153.192 24.3315 153.485 24.2662 153.777 24.201C153.94 24.6577 154.037 25.1145 154.07 25.5712C154.102 26.028 154.102 26.4521 154.102 26.7784C153.647 26.9089 153.192 26.9741 152.705 27.0394C152.185 27.1046 151.665 27.1372 151.145 27.1046C149.813 27.1699 148.481 26.7784 147.409 25.9301C146.499 25.1471 146.077 23.8421 146.077 22.015V12.26Z"
      fill="#4B5161"
    />
    <path
      d="M164.954 18.7525C164.629 18.7199 164.207 18.6546 163.687 18.622C163.167 18.5894 162.745 18.5567 162.387 18.5567C161.282 18.4915 160.21 18.7525 159.268 19.3398C158.521 19.8944 158.131 20.7753 158.196 21.6888C158.163 22.2434 158.293 22.7981 158.553 23.2874C158.781 23.6463 159.106 23.94 159.463 24.1683C159.853 24.3641 160.243 24.4946 160.665 24.5272C161.088 24.5598 161.51 24.5925 161.932 24.5925C162.452 24.5925 162.972 24.5598 163.492 24.5272C163.979 24.4946 164.466 24.3967 164.921 24.2662L164.954 18.7525ZM164.954 15.4899C164.954 14.1523 164.629 13.2388 163.947 12.7168C163.102 12.1295 162.095 11.8685 161.055 11.9338C160.34 11.9338 159.593 11.999 158.878 12.1295C158.196 12.26 157.546 12.4231 156.896 12.6189C156.474 11.8033 156.247 10.8897 156.279 9.97622C157.091 9.71522 157.904 9.51946 158.748 9.38896C159.626 9.25846 160.503 9.19321 161.38 9.19321C163.589 9.19321 165.279 9.71522 166.416 10.7266C167.553 11.738 168.138 13.3693 168.138 15.6204V26.4521C167.358 26.6152 166.416 26.811 165.311 27.0068C164.207 27.2025 163.069 27.3004 161.932 27.3004C160.958 27.3004 159.95 27.2025 159.008 27.0068C158.196 26.8436 157.449 26.5174 156.799 26.028C156.182 25.6039 155.694 25.0166 155.369 24.3315C155.012 23.5484 154.85 22.6676 154.882 21.7867C154.85 20.0575 155.629 18.4262 157.026 17.4148C157.709 16.9255 158.488 16.5666 159.301 16.3382C160.178 16.1098 161.088 15.9793 162.03 15.9793C162.745 15.9793 163.329 15.9793 163.752 16.0119C164.207 16.0446 164.596 16.0772 164.889 16.1424L164.954 15.4899Z"
      fill="#4B5161"
    />
    <path
      d="M2.34238 1.16729C2.34238 1.13466 2.37487 1.10204 2.37487 1.10204C2.40736 1.10204 2.40736 1.10204 2.43985 1.06941C2.66728 0.971537 3.28458 0.841035 4.06434 1.42829C7.70319 4.10359 15.2408 9.48681 21.7063 10.2372C21.7063 10.2372 20.1468 14.609 19.8544 15.2942C19.8544 15.3268 19.7894 15.3594 19.7569 15.3594C15.9556 14.87 1.72507 7.52927 0.522949 5.93062L2.34238 1.16729Z"
      fill="#354D82"
    />
    <path
      d="M20.6012 29.943C20.6012 29.9756 20.5688 29.9756 20.5363 29.9756C20.5038 29.9756 20.5038 30.0083 20.4713 30.0083C20.2439 30.1061 19.6266 30.2366 18.8468 29.6494C15.2079 26.9741 7.6703 21.5909 1.20483 20.8405C1.20483 20.8405 2.63439 16.8928 2.99177 15.914C3.02426 15.8162 3.15422 15.7183 3.28418 15.7509C7.31292 16.3708 21.2185 23.581 22.4207 25.1797L20.6012 29.943Z"
      fill="#354D82"
    />
    <path
      d="M22.8433 22.9286C22.7133 22.2761 22.4209 21.6888 22.0635 21.1342C21.0888 19.6008 19.7242 18.2631 18.3922 17.056C18.1647 16.8602 17.9698 16.6645 17.7424 16.4687C17.4175 16.1751 17.0601 15.8815 16.7027 15.5878C16.4428 15.3921 16.1829 15.1963 15.9554 15.0006C14.1685 13.6303 12.2516 12.4231 10.4646 11.0529C8.7102 9.71523 7.02073 8.31233 5.46122 6.77893C4.71395 6.02854 4.03167 5.27815 3.41436 4.42989C3.31689 4.29939 3.21942 4.13626 3.08946 3.97313C2.76456 3.48375 2.50465 2.96174 2.3422 2.40711C2.30971 2.1461 2.27722 1.75459 2.3422 1.52622C2.37469 1.29784 2.47216 1.06946 2.47216 1.06946C2.47216 1.06946 2.37469 1.10208 2.30971 1.16733C1.98481 1.49359 1.82236 2.11348 1.62742 2.50498C1.23754 3.45112 0.880155 4.39726 0.555256 5.37603C0.360318 5.96329 0.197868 6.51792 0.0679092 7.13781C0.0029296 7.49669 -0.0295602 7.82294 0.0354194 8.18183C0.165379 8.83434 0.457787 9.4216 0.815175 9.97623C1.78987 11.5096 3.15444 12.8473 4.48652 14.0544C4.71395 14.2502 4.90889 14.4459 5.13632 14.6417C5.46122 14.9353 5.81861 15.2616 6.17599 15.5226C7.24816 16.4035 8.35281 17.2517 9.48995 18.0674C11.2769 19.3724 13.1613 20.5469 14.8508 21.9824C15.728 22.7328 16.5727 23.4832 17.385 24.2988C18.1322 25.0492 18.8145 25.7996 19.4318 26.6479C19.5293 26.7784 19.6268 26.9415 19.7242 27.1046C20.0491 27.594 20.3091 28.116 20.4715 28.6707C20.504 28.899 20.5365 29.2905 20.504 29.4863C20.4715 29.6494 20.3416 30.0083 20.3416 30.0083C20.3416 30.0083 20.4715 29.9757 20.5365 29.9104C20.8614 29.5842 21.0238 28.9643 21.2188 28.5728C21.7061 27.4309 22.1285 26.2564 22.4859 25.0819C22.5833 24.7556 22.6808 24.462 22.7458 24.1357C22.8758 23.7116 22.9082 23.3527 22.8433 22.9286Z"
      fill="#458FCE"
    />
    <path
      d="M22.1285 8.76906C22.0635 8.4428 21.901 8.11655 21.7386 7.82292C21.2512 7.00728 20.6014 6.32214 19.9516 5.66963C19.3343 5.04975 18.652 4.46249 17.9373 3.94048C17.255 3.48372 16.5402 3.09221 15.8579 2.60283C15.1756 2.11345 14.5258 1.52619 14.1685 0.743174C14.1035 0.580046 13.6161 -0.431346 13.2263 0.547421C12.8364 1.55881 12.4465 2.5702 12.1541 3.5816C11.9592 4.20148 11.7967 4.82137 11.7317 5.47388C11.7317 5.63701 11.6992 5.80013 11.7317 5.96326C11.8617 7.33353 12.9338 8.21443 13.941 8.89956C14.6233 9.35632 15.3706 9.74783 16.0529 10.2372C16.8326 10.8245 17.5799 11.5096 18.2622 12.2274C18.912 12.9125 19.6592 13.7281 19.9192 14.6417C19.9192 14.7069 19.9516 14.8374 19.9192 14.87C19.8867 15.0984 19.6917 15.2289 19.5943 15.2615C19.7892 15.3268 19.8867 15.2942 19.9516 15.2942C20.0816 15.2615 20.1466 15.131 20.1791 15.0984C20.2116 15.0332 20.374 14.7069 20.374 14.7069V14.6743C20.7964 13.826 21.2837 12.3579 21.3162 12.3252C21.6736 11.3139 22.0635 10.3025 22.2259 9.22582C22.1934 9.12794 22.1934 8.93219 22.1285 8.76906Z"
      fill="#458FCE"
    />
    <path
      d="M11.2768 25.1471C11.1468 23.7768 10.0746 22.8959 9.06745 22.2108C8.38517 21.754 7.6379 21.3625 6.95561 20.8731C6.17586 20.2859 5.42859 19.6007 4.74631 18.883C4.129 18.2305 3.41423 17.4148 3.12182 16.5339C3.08933 16.4034 3.08933 16.2729 3.12182 16.2077C3.1868 16.0119 3.34925 15.8814 3.47921 15.8488C3.25178 15.7835 3.02435 15.7835 2.95937 15.8814C2.92688 15.914 2.89439 15.9793 2.8619 15.9793C2.43953 16.6318 1.72476 18.6546 1.69227 18.7198C1.33488 19.6986 0.945001 20.7426 0.815042 21.7866C0.782552 21.9498 0.782552 22.1455 0.847532 22.3086C0.912512 22.6349 1.07496 22.9612 1.23741 23.2548C1.72476 24.0704 2.37455 24.7556 3.02435 25.4081C3.64165 26.028 4.32394 26.6152 5.03872 27.1372C5.721 27.594 6.43578 27.9855 7.11806 28.4749C7.80035 28.9643 8.45015 29.5515 8.80753 30.3345C8.87251 30.4977 9.35986 31.509 9.74974 30.5303C10.1396 29.5189 10.5295 28.5075 10.8219 27.4961C11.0168 26.8762 11.1793 26.2563 11.2443 25.6038C11.2768 25.4733 11.2768 25.3102 11.2768 25.1471Z"
      fill="#458FCE"
    />
  </svg>
);

const BackendPaymentHistoryElement = {
  id: PropTypes.string.isRequired,
  gateway: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  timestamp_ms: PropTypes.number.isRequired
};

const PaymentHistoryRow = ({ id, gateway, amount, timestamp_ms }) => (
  <tr key={`${gateway}:${id}:${timestamp_ms}`}>
    <td data-pdfmake={`{"lineHeight": "1.5"}`}>{localeDate(timestamp_ms)}</td>
    <td data-pdfmake={`{"lineHeight": "1.5", "alignment": "right"}`}>
      {formatMoneyAmount(amount)}
    </td>
  </tr>
);
PaymentHistoryRow.propTypes = BackendPaymentHistoryElement;

const PaymentHistoryPage = ({ user, payments, year }) => (
  <div>
    <AlephBetaLogo />
    <br />
    <h1>Tax Receipt for:</h1>
    <br />
    <p data-pdfmake={`{"lineHeight": "1.5"}`}>
      {user.meta_first_name || '[Unknown first name]'}{' '}
      {user.meta_last_name || '[Unknown last name]'}
      <br />
      {user.username}
      <br />
      {user.meta_address || '[Please specify your address line]'}
      <br />
      {user.meta_city || '[Please specify your city]'},{' '}
      {user.meta_country || '[Please specify your country]'}{' '}
      {user.meta_zip || '[Please specify your ZIP code]'}
      <br />
      {user.meta_phone || '[Please specify your phone]'}
      <br />
      <br />
    </p>
    <h1>{year} Donations:</h1>
    <br />
    <table data-pdfmake={`{"layout":"noBorders"}`}>
      <thead>
        <tr>
          <th data-pdfmake={`{"fillColor": "#ffffff", "lineHeight": "1.5" }`}>
            Donation&nbsp;Date
          </th>
          <th data-pdfmake={`{"fillColor": "#ffffff", "lineHeight": "1.5", "alignment": "right" }`}>
            Donation&nbsp;Amount
          </th>
        </tr>
      </thead>
      <tbody>{payments.map(PaymentHistoryRow)}</tbody>
      <tfoot>
        <tr>
          <td data-pdfmake={`{"lineHeight": "1.5", "bold": "true"}`}>
            Total tax Deductible Donations
          </td>
          <td data-pdfmake={`{"lineHeight": "1.5", "bold": "true", "alignment": "right"}`}>
            {formatMoneyAmount(payments.reduce((total, payment) => total + payment.amount, 0))}
          </td>
        </tr>
      </tfoot>
    </table>

    <br />

    <p className="note">
      Aleph Beta is officially listed by the IRS as a 501(c)(3) non-for-profit organization under
      the name Hoffberger Fund for Biblical Studies, LTD. Our EIN is 27-3846145.
    </p>
    <p className="note">
      Aleph Beta - Hoffberger Institute for Text Study, Inc 3 E Evergreen Rd #708 New City, NY 10956
    </p>
  </div>
);

PaymentHistoryPage.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    meta_first_name: PropTypes.string,
    meta_last_name: PropTypes.string,
    meta_city: PropTypes.string,
    meta_address: PropTypes.string,
    meta_country: PropTypes.string,
    meta_zip: PropTypes.string,
    meta_phone: PropTypes.string
  }).isRequired,
  payments: PropTypes.arrayOf(PropTypes.shape(BackendPaymentHistoryElement)),
  year: PropTypes.number.isRequired
};
PaymentHistoryPage.defaultProps = {
  payments: []
};

export default PaymentHistoryPage;

export const docDefinitionsOverride = {
  defaultStyle: {
    font: 'Roboto',
    color: '#525867'
  },
  styles: {
    note: {
      fontSize: 10,
      color: '#818691'
    }
  }
};
