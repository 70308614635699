import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/styles/makeStyles';
import { Typography } from '@material-ui/core';
import Link from '~src/components/Link';

const useStyles = makeStyles(theme => ({
  wrapper: {
    textAlign: 'center',
    border: '1px solid rgba(75,81,97,0.2)',
    width: '100%',
    maxWidth: '700px'
  },
  header: {
    backgroundColor: '#55BFB8',
    padding: '43px 0 37px 0',
    color: 'white'
  },
  title: {
    fontWeight: 500,
    fontSize: '24px',
    marginBottom: '20px'
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '36px'
  },
  contentWrapper: {
    padding: '20px'
  },
  content: {
    fontWeight: 400,
    color: '#4B5161',
    marginBottom: '1em'
  },
  buttonWrapper: {
    margin: '1em 0'
  },
  [theme.breakpoints.up('md')]: {
    contentWrapper: {
      padding: '34px 53px 20px'
    }
  }
}));

const DiscountBanner = ({
  title,
  subtitle,
  children,
  button,
  hasPayPalNote,
  hasUnemployedNote
}) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.subtitle}>{subtitle}</Typography>
      </div>
      <div className={classes.contentWrapper}>
        <Typography className={classes.content}>{children}</Typography>
        {hasPayPalNote && (
          <Typography className={classes.content}>
            We see you are a PayPal subscriber. Automatic discounts cannot be applied to PayPal
            accounts. Switch your payment method to credit card and use coupon code STAY50 to redeem
            this offer.
          </Typography>
        )}
        <div className={classes.buttonWrapper}>{button}</div>
        {hasUnemployedNote && (
          <Typography className={classes.content}>
            If you’re struggling financially or unemployed, please{' '}
            <Link to="mailto:support@alephbeta.org">reach out</Link> and we’ll work with you.
          </Typography>
        )}
      </div>
    </div>
  );
};

DiscountBanner.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  button: PropTypes.element.isRequired,
  hasPayPalNote: PropTypes.bool,
  hasUnemployedNote: PropTypes.bool
};
DiscountBanner.defaultProps = {
  hasPayPalNote: false,
  hasUnemployedNote: false
};

export default DiscountBanner;
