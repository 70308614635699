import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  subtitle: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '21,09px',
    color: props => (props.isImportant ? '#E5646F' : '#4B5161'),
    opacity: props => (props.isPale ? 0.7 : 0.96),
    marginBottom: '1em'
  }
});

const Subtitle = ({ children, isPale, isImportant }) => {
  const classes = useStyles({ isPale, isImportant });
  return (
    <Typography variant="subtitle1" className={classes.subtitle}>
      {children}
    </Typography>
  );
};

Subtitle.propTypes = {
  children: PropTypes.node.isRequired,
  isPale: PropTypes.bool,
  isImportant: PropTypes.bool
};
Subtitle.defaultProps = {
  isPale: false,
  isImportant: false
};

export default Subtitle;
