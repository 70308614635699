import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const AccountCta = ({ title, link, linkText, ...rest }) => (
  <Box p={4} bgcolor="#f5f5f5" textAlign="center" {...rest}>
    <Box my={1}>
      <Typography variant="h6" component="p">
        {title}
      </Typography>
    </Box>

    <Box my={1}>
      <Button component={GatsbyLink} variant="contained" size="large" color="primary" to={link}>
        {linkText}
      </Button>
    </Box>
  </Box>
);

AccountCta.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired
};

export default AccountCta;
