import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import Link from '~components/Link';
import Divider from '~src/components/Divider';
import Header from './elements/Header';
import Subtitle from './elements/Subtitle';
import Button from './elements/Button';

const CounterOfferContentAccepted = ({ serverMessage = undefined }) => (
  <>
    <Header>Yay!</Header>

    <Subtitle>
      We’re so happy you decided to stay. Thank you for reconsidering. We won’t let you down! Your
      feedback is important to us. Would you mind filling out{' '}
      <Link to="https://forms.gle/Rw9FE3jiRXHFq7gDA">this quick survey</Link>?
    </Subtitle>

    {serverMessage && <Subtitle isImportant>{serverMessage}</Subtitle>}

    <Divider />

    <Button onClick={() => navigate('/')}>Continue Learning</Button>
  </>
);

CounterOfferContentAccepted.propTypes = {
  serverMessage: PropTypes.string
};
CounterOfferContentAccepted.defaultProps = {
  serverMessage: undefined
};
export default CounterOfferContentAccepted;
