import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/styles/makeStyles';
import { Typography } from '@material-ui/core';
import Link from '~src/components/Link';
import AppStoreIcon from '../images/app-store.png';
import GooglePlayIcon from '../images/google-play.png';

const useStyles = makeStyles({
  wrapper: {
    textAlign: 'center',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    fontWeight: 500,
    fontSize: '20px',
    marginBottom: '20px'
  }
});

const MobileAppsBanner = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title}>{children}</Typography>
      <Link to="https://itunes.apple.com/app/apple-store/id598939260">
        <img src={AppStoreIcon} alt="App Store" />
      </Link>
      <Link to="https://play.google.com/store/apps/details?id=org.alephbeta.android&amp;hl=en">
        <img src={GooglePlayIcon} alt="Google Play" />
      </Link>
    </div>
  );
};

MobileAppsBanner.propTypes = {
  children: PropTypes.node.isRequired
};

export default MobileAppsBanner;
