import React from 'react';

import { navigate } from 'gatsby';
import { Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Divider from '~src/components/Divider';
import { useUserContext } from '~src/context/UserContext/UserSessionContext';
import AccountBillingPopupImage from './images/popup-offboarding-sliced.png';
import Header from './elements/Header';
import Subtitle from './elements/Subtitle';
import Button from './elements/Button';
import LinkButton from './elements/LinkButton';

const useStyles = makeStyles({
  imgGrid: { minHeight: '220px' },
  image: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    maxWidth: '100%'
  }
});

const CounterOfferMobile = () => {
  const classes = useStyles();
  const { session } = useUserContext();
  const isGPlay = !!session.user.subscription_gateway_gplay_id;
  const handleChangedMyMind = () => {
    navigate('/account/billing');
  };

  return (
    <>
      <Header>We are sorry to see you go.</Header>

      <Subtitle isPale>
        Since you subscribed via {isGPlay ? 'Google Play' : 'Apple App Store'}, please be advised
        you will need to cancel your subscription manually to avoid future charges.
      </Subtitle>

      <Grid container direction="row">
        <Grid item xs={12} md={6}>
          <Divider />
          <Button onClick={handleChangedMyMind}>Changed my mind</Button>
          <LinkButton
            target="blank"
            href={
              isGPlay
                ? 'https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=en'
                : 'https://support.apple.com/en-us/HT202039'
            }
          >
            How to cancel {isGPlay ? 'Google Play' : 'Apple App Store'} subscription
          </LinkButton>
        </Grid>
        <Grid item xs={12} md={6} className={classes.imgGrid}>
          <img
            src={AccountBillingPopupImage}
            alt="Cancel subscription popup"
            className={classes.image}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CounterOfferMobile;
