import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import useAccount from '~hooks/useAccount';

import MessageBox from '~components/forms/MessageBox';
import PasswordInput from '~components/form-inputs/PasswordInput';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import PopupCloseButton from '../shared/PopupCloseButton';
import PopupHeading from '../shared/PopupHeading';

const PasswordForm = ({ onClose }) => {
  const { register, handleSubmit } = useForm();
  const { state, updatePassword } = useAccount();
  const { isLoading, isError, data, error } = state;

  const [isValidationError, setIsValidationError] = useState(false);

  const validationErrorMessage = "These passwords don't match. Try again";

  const onSubmit = ({ new_password, confirm_new_password }) => {
    if (new_password === confirm_new_password) {
      updatePassword({ new_password });
    } else {
      setIsValidationError(true);
    }
  };

  if (data) {
    onClose();
  }

  return (
    <div style={{ position: 'relative' }}>
      <PopupHeading title="Change Password" />

      <form onSubmit={handleSubmit(onSubmit)}>
        {isError && <MessageBox text={error} isError />}

        {!data && (
          <>
            <PasswordInput
              label="New Password"
              name="new_password"
              inputRef={register}
              disabled={isLoading}
              error={isValidationError}
            />

            <PasswordInput
              label="Confirm New Password"
              name="confirm_new_password"
              inputRef={register}
              disabled={isLoading}
              error={isValidationError}
              helperText={isValidationError ? validationErrorMessage : ''}
            />

            <ButtonSubmit fullWidth disabled={isLoading} style={{ boxShadow: 'unset' }}>
              Save Changes
            </ButtonSubmit>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={onClose}
              disabled={isLoading}
              style={{ boxShadow: 'unset' }}
            >
              Cancel
            </Button>
          </>
        )}
      </form>
      <PopupCloseButton handleClose={onClose} />
    </div>
  );
};

PasswordForm.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default PasswordForm;
