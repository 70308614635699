import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  buttonStyle: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#4B5161',
    marginBottom: '8px'
  }
});

const PopupHeading = ({ title }) => {
  const classes = useStyles();
  return (
    <Typography align="left" className={classes.buttonStyle}>
      {title}
    </Typography>
  );
};

PopupHeading.propTypes = {
  title: PropTypes.string.isRequired
};

export default PopupHeading;
