import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { navigate } from 'gatsby';
import Divider from '~src/components/Divider';
import Header from './elements/Header';
import Subtitle from './elements/Subtitle';
import Button from './elements/Button';
import MobileAppsBanner from './elements/MobileAppsBanner';

const CounterOfferAppAccepted = ({ serverMessage = undefined }) => (
  <>
    <Header>Great choice!</Header>

    {serverMessage && <Subtitle isImportant>{serverMessage}</Subtitle>}

    <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
      <Grid item xs={9}>
        <Subtitle>
          We’re so excited for you to stay. We hope our app helps you fit Torah into your day!
          Please let us know what you think. Your 50% off 3 months of membership has been applied to
          your account. No action needed.
        </Subtitle>
        <Divider />

        <Button onClick={() => navigate('/')}>Continue Learning</Button>
      </Grid>
      <Grid item xs={3}>
        <MobileAppsBanner>Download our app here:</MobileAppsBanner>
      </Grid>
    </Grid>
  </>
);

CounterOfferAppAccepted.propTypes = {
  serverMessage: PropTypes.string
};
CounterOfferAppAccepted.defaultProps = {
  serverMessage: undefined
};
export default CounterOfferAppAccepted;
