import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { navigate } from 'gatsby';
import { Grid } from '@material-ui/core';
import Divider from '~src/components/Divider';
import AccountBillingPopupImage from './images/popup-offboarding-sliced.png';
import Header from './elements/Header';
import Subtitle from './elements/Subtitle';
import LinkButton from './elements/LinkButton';
import Button from './elements/Button';

const CounterOfferSelectorScreen = ({ handleSwitchScreen, offers }) => {
  const [selectedOfferId, setSelectedOfferId] = useState(null);

  // NOTE: `target.value` WILL be String, because that's how HTML elements work.
  const changeOffer = ({ target }) => {
    setSelectedOfferId(+target.value);
  };

  const proceedWithSelectedOffer = () => {
    handleSwitchScreen(selectedOfferId);
  };

  return (
    <>
      <Header>Let us know why you’re canceling:</Header>

      <Subtitle isPale>
        If you have a minute, please share your reason for canceling with us. We’d love the
        opportunity to improve.
      </Subtitle>

      <Grid container direction="row">
        <Grid item xs={12} md={6}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="counter-offer"
              name="counter-offer"
              value={`${selectedOfferId}`}
              onChange={changeOffer}
            >
              {offers.map(offer => (
                <FormControlLabel
                  key={offer.screenId}
                  value={`${offer.screenId}`}
                  control={<Radio />}
                  label={offer.title}
                />
              ))}
            </RadioGroup>
          </FormControl>

          <Divider />

          <Button onClick={() => navigate('/account')}>Back</Button>

          {selectedOfferId && (
            <LinkButton onClick={proceedWithSelectedOffer}>Continue canceling</LinkButton>
          )}
        </Grid>
        <Grid item xs={12} md={6} style={{ minHeight: '220px' }}>
          <img
            src={AccountBillingPopupImage}
            alt="Cancel subscription popup"
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              maxWidth: '100%'
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

CounterOfferSelectorScreen.propTypes = {
  handleSwitchScreen: PropTypes.func.isRequired,
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      handler: PropTypes.func
    })
  ).isRequired
};

export default CounterOfferSelectorScreen;
