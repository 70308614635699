import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { localeDate } from '~utils/dates';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { producersCircleEventClick } from '~utils/analytics';
import { pushDataLayerEvent } from '~src/utils/data-layer';
import Link from '~components/Link';

const useStyles = makeStyles(theme => ({
  heading: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '28px',
    color: '#4B5161',
    marginBottom: '14px'
  },
  eventTitle: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#4B5161',
    marginBottom: '15px'
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#4B5161',
    opacity: 0.96
  },
  helperText: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#4B5161',
    opacity: 0.96,
    marginTop: '14px'
  },
  eventSubtitle: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#4B5161',
    opacity: 0.96,
    marginBottom: '24px'
  },
  cardOverflow: {
    maxHeight: '700px',
    overflowY: 'auto',
    scrollbarColor: '#C4C4C4 transparent',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '15px',
      backgroundColor: '#C4C4C4'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      borderRadius: '10px'
    },

    [theme.breakpoints.down('md')]: {
      maxHeight: 'unset',
      overflowY: 'auto'
    }
  },
  expandLine: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '500',
    color: '#458FCE',
    textTransform: 'uppercase',
    cursor: 'pointer',
    textAlign: 'center',
    margin: '0 auto',
    marginTop: '30px',
    opacity: '0.87'
  },
  dateBox: {
    backgroundColor: props => (props.isUpcoming ? '#6A52C4 ' : '#F9F2E8'),
    color: props => (props.isUpcoming ? '#FFF' : '#686662'),
    [theme.breakpoints.down('md')]: {
      marginBottom: '25px'
    }
  },
  dateBoxDay: {
    fontWeight: 500,
    fontSize: '36px',
    lineHeight: '42px'
  },
  dateBoxMonth: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px'
  },
  gridSpacing: {
    marginTop: '35px',
    marginBottom: '13px'
  },
  linkButton: {
    fontSize: ' 0.875rem',
    color: '#438dce',
    padding: 0,
    fontWeight: 400,
    textDecoration: 'underline',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'unset',
      textDecoration: 'underline'
    }
  },
  thumbnail: {
    maxWidth: '100%',
    height: 'auto'
  }
}));

const ProducersCircleInformationCard = ({
  title,
  subtitle,
  events,
  isUpcoming,
  isFirstCard,
  playlistCoverImage,
  ...rest
}) => {
  const classes = useStyles({ isUpcoming });

  const [isEventsExpanded, setIsEventsExpanded] = useState(false);

  const isEventsLengthShorterThanTwo = events && events.length < 2;

  const eventsToShow =
    isEventsExpanded || isEventsLengthShorterThanTwo ? events : events.slice(0, 2);

  const handleExpansionClick = () => {
    setIsEventsExpanded(true);
  };

  const handleLinkClick = (eventLink, eventTitle, eventDate) => {
    producersCircleEventClick(eventLink, eventTitle, eventDate);
    pushDataLayerEvent('producers_circle_event_link_clicked', { eventLink, eventTitle, eventDate });

    window.open(eventLink, '_blank');
  };

  return (
    <Box
      bgcolor={isFirstCard ? '#E6EDFF' : '#FFFFFF'}
      p={4}
      pl={5}
      borderBottom="1px solid rgba(0, 0, 0, 0.1)"
      className={classes.cardOverflow}
      {...rest}
    >
      {title && <Typography className={classes.heading}>{title}</Typography>}
      {subtitle && <Typography className={classes.subtitle}>{subtitle}</Typography>}
      <Grid container direction="column" justify="space-between" alignItems="center">
        {(!events || !eventsToShow || !eventsToShow.length) && (
          <Grid item>
            <Typography className={classes.helperText}>
              {isUpcoming ? 'There are no upcoming events.' : 'There are no past events.'}
            </Typography>
          </Grid>
        )}

        {events &&
          eventsToShow &&
          eventsToShow.map(event => (
            <Grid
              key={event.event_date}
              item
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
              className={classes.gridSpacing}
            >
              {playlistCoverImage ? (
                <Grid item xs={12}>
                  <Link to={event.event_link.url}>
                    <img
                      className={classes.thumbnail}
                      src={playlistCoverImage.url}
                      alt={playlistCoverImage.alt}
                    />
                  </Link>
                </Grid>
              ) : (
                <Grid item xs={12} sm="auto" md="auto">
                  <Box
                    width="100%"
                    display="block"
                    textAlign="center"
                    minWidth="95px"
                    p={1}
                    py={2}
                    className={classes.dateBox}
                  >
                    <Typography className={classes.dateBoxDay}>
                      {localeDate(event.event_date, { day: 'numeric', timeZone: 'UTC' })}
                    </Typography>
                    <Typography className={classes.dateBoxMonth}>
                      {localeDate(event.event_date, { month: 'long', timeZone: 'UTC' })}
                    </Typography>
                  </Box>
                </Grid>
              )}

              <Grid item xs={12} md={playlistCoverImage ? null : 9}>
                <Typography align="left" className={classes.eventTitle}>
                  {event.event_title}
                </Typography>
                <Typography align="left" className={classes.eventSubtitle}>
                  {event.event_subtitle}
                </Typography>
                {event.event_link && event.event_link.url && (
                  <Button
                    onClick={() =>
                      handleLinkClick(event.event_link.url, event.event_title, event.event_date)
                    }
                    className={classes.linkButton}
                    disableRipple
                    disableTouchRipple
                    disableFocusRipple
                  >
                    {event.link_display_text}
                  </Button>
                )}
              </Grid>
            </Grid>
          ))}
        {!isEventsExpanded && !isEventsLengthShorterThanTwo && (
          <Typography className={classes.expandLine} onClick={handleExpansionClick}>
            Show All
          </Typography>
        )}
      </Grid>
    </Box>
  );
};

ProducersCircleInformationCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  events: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  isUpcoming: PropTypes.bool,
  isFirstCard: PropTypes.bool,
  playlistCoverImage: PropTypes.shape({ alt: PropTypes.string, url: PropTypes.string })
};

ProducersCircleInformationCard.defaultProps = {
  title: null,
  subtitle: null,
  isUpcoming: false,
  isFirstCard: false,
  playlistCoverImage: null
};

export default ProducersCircleInformationCard;
