import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import InformationCard from '../InformationCard';
import {
  calculateSubscriptionData,
  calculatePaymentData,
  calculateCancellationData,
  calculatePersonalData,
  calculateEmailData,
  calculatePasswordData,
  calculateTaxReceipts,
  calculateWatchHistory,
  CancellationMode
} from '../../account.utils';

const useStyles = makeStyles({
  left_col_padding: {
    padding: '16px 16px 16px 0px'
  },
  right_col_padding: {
    padding: '16px 0px 16px 16px'
  }
});

const OverviewLayout = ({
  user,
  isSubscriber,
  isPrivilegedMember,
  isProducer,
  isEducator,
  onStripeSuccess,
  /* isAdmin, */ handlePopupOpenClick
}) => {
  const classes = useStyles();
  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
      <Grid item xs={12} md={6} className={classes.left_col_padding}>
        <InformationCard
          title="Your Subscription"
          {...calculateSubscriptionData(user, isPrivilegedMember, isProducer, isEducator)}
        />
        <InformationCard
          title="Payment Information"
          onButtonClick={handlePopupOpenClick}
          onStripeSuccess={onStripeSuccess}
          username={user.username}
          {...calculatePaymentData(user, isSubscriber)}
          mt={4}
        />
        {isSubscriber && (
          <InformationCard
            onButtonClick={handlePopupOpenClick}
            {...calculateCancellationData({ mode: CancellationMode.DIRECT })}
          />
        )}
        <InformationCard onButtonClick={handlePopupOpenClick} {...calculateTaxReceipts()} />
      </Grid>
      <Grid item xs={12} md={6} className={classes.right_col_padding}>
        <InformationCard
          title="Your Account"
          onButtonClick={handlePopupOpenClick}
          {...calculatePersonalData(user)}
          isPersonalInfo
        />
        <InformationCard onButtonClick={handlePopupOpenClick} {...calculateEmailData(user)} />
        <InformationCard onButtonClick={handlePopupOpenClick} {...calculatePasswordData()} />
        <InformationCard mt={4} {...calculateWatchHistory()} />
      </Grid>
    </Grid>
  );
};

OverviewLayout.propTypes = {
  isSubscriber: PropTypes.bool.isRequired,
  isPrivilegedMember: PropTypes.bool.isRequired,
  isProducer: PropTypes.bool.isRequired,
  isEducator: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  onStripeSuccess: PropTypes.func,
  handlePopupOpenClick: PropTypes.func,
  user: PropTypes.shape({
    username: PropTypes.string.isRequired
  }).isRequired
};

OverviewLayout.defaultProps = {
  onStripeSuccess: null,
  handlePopupOpenClick: null
};

export default OverviewLayout;
