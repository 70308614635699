import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  heading: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '28px',
    color: '#4B5161',
    marginBottom: '1em'
  }
});

const Header = ({ children }) => {
  const classes = useStyles();
  return (
    <Typography variant="h4" className={classes.heading}>
      {children}
    </Typography>
  );
};

Header.propTypes = {
  children: PropTypes.node.isRequired
};

export default Header;
