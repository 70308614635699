import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import Divider from '~src/components/Divider';
import Header from './elements/Header';
import Subtitle from './elements/Subtitle';
import Button from './elements/Button';

const CounterOfferCustomerSupportAccepted = ({ serverMessage = undefined }) => (
  <>
    <Header>Thank you!</Header>

    <Subtitle>
      Keep an eye on your inbox. A member of the Aleph Beta Customer happiness team will be writing
      to you very soon.
    </Subtitle>

    {serverMessage && <Subtitle isImportant>{serverMessage}</Subtitle>}

    <Divider />

    <Button onClick={() => navigate('/account')}>Manage Account</Button>
  </>
);

CounterOfferCustomerSupportAccepted.propTypes = {
  serverMessage: PropTypes.string
};
CounterOfferCustomerSupportAccepted.defaultProps = {
  serverMessage: undefined
};
export default CounterOfferCustomerSupportAccepted;
