import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  tableCellStyle: {
    '& td': {
      padding: '18px 8px'
    },
    '& th': {
      padding: '18px 8px'
    },
    overflowX: 'auto'
  }
});

const AccountEducationStudents = ({ students }) => {
  const classes = useStyles();
  return (
    <Box component={Paper} my={1} elevation={4} className={classes.tableCellStyle}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {students &&
            students.map((student, index) => (
              <TableRow key={student.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{student.meta_first_name}</TableCell>
                <TableCell>{student.meta_last_name}</TableCell>

                <TableCell>{student.username}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
};

AccountEducationStudents.propTypes = {
  students: PropTypes.arrayOf(PropTypes.shape())
};

AccountEducationStudents.defaultProps = {
  students: []
};

export default AccountEducationStudents;
