import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { calculateButtonComponent } from '../../account.utils';

const useStyles = makeStyles({
  heading: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '28px',
    color: '#4B5161',
    marginBottom: '40px'
  },
  col_first_item: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#4B5161',
    marginBottom: '22px'
  },

  col_item: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#4B5161',
    marginBottom: '22px'
  },
  button_style: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    marginBottom: '16px',
    marginRight: '-8px',
    marginTop: '-6px'
  },
  align_right: {
    textAlign: 'right'
  }
});

const InformationCard = ({
  title,
  button,
  leftColItems,
  rightColItems,
  onButtonClick,
  onStripeSuccess,
  username,
  isPersonalInfo,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Box bgcolor="#fff" p={5} pb={2} borderBottom="1px solid rgba(0, 0, 0, 0.1)" {...rest}>
      {title && <Typography className={classes.heading}>{title}</Typography>}
      <Grid container direction="row" justify="space-between" alignItems="flex-start">
        <Grid item xs={7}>
          {leftColItems &&
            leftColItems.map((item, index) => (
              <Typography
                align="left"
                className={index === 0 ? classes.col_first_item : classes.col_item}
                style={{ marginBottom: index !== 0 && isPersonalInfo ? '0px' : '' }}
              >
                {item.text}
              </Typography>
            ))}
        </Grid>
        <Grid item xs={5} className={classes.align_right}>
          {calculateButtonComponent(button, onButtonClick, onStripeSuccess, username)}
          {rightColItems &&
            rightColItems.map(item => (
              <Typography align="right" className={classes.col_item}>
                {item.text}
              </Typography>
            ))}
        </Grid>
      </Grid>
    </Box>
  );
};

InformationCard.propTypes = {
  title: PropTypes.string,
  leftColItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired
    })
  ).isRequired,
  rightColItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired
    })
  ).isRequired,
  button: PropTypes.shape({
    title: PropTypes.string.isRequired
  }).isRequired,
  onStripeSuccess: PropTypes.func,
  onButtonClick: PropTypes.func,
  username: PropTypes.string.isRequired,
  isPersonalInfo: PropTypes.bool
};

InformationCard.defaultProps = {
  title: null,
  onStripeSuccess: null,
  onButtonClick: null,
  isPersonalInfo: false
};

export default InformationCard;
