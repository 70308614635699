import React from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/styles/makeStyles';

import MessageBox from '~components/forms/MessageBox';
import TextInput from '~components/form-inputs/TextInput';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import PopupCloseButton from '../shared/PopupCloseButton';

const useStyles = makeStyles(theme => ({
  gridSpacing: {
    paddingRight: '12px',
    [theme.breakpoints.down('md')]: {
      paddingRight: 'unset'
    }
  },
  heading: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#4B5161',
    marginBottom: '8px'
  }
}));

const EducationStudentForm = ({ onClose, handleStudentSubmit, isLoading, isError, error }) => {
  const classes = useStyles();
  const { register, handleSubmit } = useForm();

  const handleStudentAdd = (formInfo, event) => {
    handleStudentSubmit(formInfo);
    event.target.reset();
    onClose();
  };

  return (
    <div style={{ position: 'relative' }}>
      <Typography align="left" className={classes.heading}>
        Add Student
      </Typography>
      <form onSubmit={handleSubmit(handleStudentAdd)}>
        {isError && <MessageBox text={error} isError />}

        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} sm={6} className={classes.gridSpacing}>
            <TextInput
              label="First name"
              name="meta_first_name"
              inputRef={register}
              disabled={isLoading}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              label="Last name"
              name="meta_last_name"
              inputRef={register}
              disabled={isLoading}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label="Email"
              name="username"
              type="email"
              inputRef={register}
              disabled={isLoading}
              required
            />
          </Grid>
        </Grid>
        <ButtonSubmit fullWidth disabled={isLoading} style={{ boxShadow: 'unset' }}>
          Add Student
        </ButtonSubmit>
      </form>
      <PopupCloseButton handleClose={onClose} />
    </div>
  );
};

EducationStudentForm.propTypes = {
  handleStudentSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired
};

export default EducationStudentForm;
