import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Link from '~components/Link';
import Divider from '~components/Divider';
import PopupCloseButton from '../shared/PopupCloseButton';
import PaypalLogoImg from './images/paypal-logo.png';
import PopupHeading from '../shared/PopupHeading';

const PaypalUpdateInfo = ({ onClose }) => (
  <div style={{ position: 'relative' }}>
    <PopupHeading title="Update Payment" />

    <Typography
      align="left"
      style={{
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '21px',
        color: '#4B5161',
        marginBottom: '27px'
      }}
    >
      Payment Method
    </Typography>
    <img
      src={PaypalLogoImg}
      alt="PayPal logo"
      style={{ marginBottom: '27px', marginTop: '10px', display: 'flex', maxWidth: '100%' }}
    />

    <Link to="https://www.paypal.com/">Update PayPal payment information with PayPal.com</Link>
    <Divider />
    <Typography
      align="left"
      style={{
        fontSize: '16px',
        lineHeight: '22px',
        marginBottom: '22px'
      }}
    >
      or swap payment method to Credit Card
    </Typography>
    <Button
      component={GatsbyLink}
      to="/subscribe"
      variant="contained"
      color="primary"
      style={{ boxShadow: 'unset', marginBottom: '21px' }}
      fullWidth
    >
      Swap to Credit Card
    </Button>
    <Button
      variant="outlined"
      color="primary"
      fullWidth
      onClick={onClose}
      style={{ boxShadow: 'unset' }}
    >
      Cancel
    </Button>

    <PopupCloseButton handleClose={onClose} />
  </div>
);
PaypalUpdateInfo.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default PaypalUpdateInfo;
