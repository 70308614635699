import React from 'react';
import { Link as GoToSection } from 'react-scroll';
import Container from '~components/Container';
import PropTypes from 'prop-types';
import {
  useMediaQuery,
  Box,
  Typography,
  FormControl,
  Input,
  InputAdornment,
  FormHelperText
} from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import {
  MAX_ALLOWED_AMOUNT,
  MIN_ALLOWED_AMOUNT,
  PREMIUM_YEARLY_PRICE_PER_MONTH_USD
} from '~modules/pay-what-you-can/constants';
import BackgroundImage from './img.png';
import SubscribePlanSwitch from '../SubscribePlanSwitch';
import JoinButton from '../JoinButton';

const useStyles = makeStyles(theme => ({
  containerMargin: {
    marginBottom: theme.spacing(3),
    padding: '30px 0'
  },
  containerMarginMobile: {
    padding: '0',
    borderRadius: '0',
    marginBottom: '40px'
  },
  decorativeWrapper: {
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '30px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)'
  },
  decorativeWrapperMobile: {
    borderRadius: '0',
    padding: '30px 30px 60px',
    '&:after': {
      content: '""',
      display: 'block',
      border: '1px solid #CCCCCC',
      width: '90%',
      position: 'absolute',
      bottom: '1em',
      left: '5%'
    }
  },
  contentWrapper: {
    maxWidth: '600px',
    textAlign: 'center'
  },
  bodyText: {
    color: '#1D245E',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '20.58px',
    marginBottom: '20px'
  },
  iWishToPay: {
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '131.19%',
    color: 'black'
  },
  priceWrapper: {
    fontSize: '42px',
    lineHeight: '131.19%',
    fontWeight: 400,
    color: '#1D245E'
  },
  priceInDollars: { fontWeight: 700 },
  sectionHeading: {
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '41.98px',
    color: '#1D245E',
    marginBottom: '20px',
    '& > small': {
      fontWeight: 400
    }
  },
  sectionSubHeading: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '31.48px',
    color: '#1D245E',
    marginBottom: '20px'
  },
  button: {
    color: 'white',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '46px',
    backgroundColor: '#0068FF',
    borderRadius: '10px',
    textAlign: 'center',
    margin: '33px auto',
    textDecoration: 'none',
    display: 'block',
    width: '209px',
    height: '46px',
    '&:hover': {
      boxShadow: '0 0 3px 1px #0068FF',
      color: 'darker'
    }
  },
  gotoLink: {
    color: '#0068FF',
    textDecoration: 'underline',
    fontWeight: 700,
    cursor: 'pointer'
  },
  inputWrapper: {
    margin: '1em auto',
    maxWidth: '300px'
  },
  billing: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '1rem',
    lineHeight: '131.19%'
  },
  input: {
    color: 'rgba(0, 0, 0, 0.54)'
  }
}));

const ChurnPaymentBlockVisuals = ({
  amount,
  setAmount,
  isAmountInvalid,
  isYearly,
  setIsYearly,
  handleCancel
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));

  const containerStyles = cn({
    [classes.containerMargin]: true,
    [classes.containerMarginMobile]: isSmallerThanSm
  });

  const decorativeWrapperStyles = cn({
    [classes.decorativeWrapper]: true,
    [classes.decorativeWrapperMobile]: isSmallerThanSm
  });

  return (
    <Container
      background={`url(${BackgroundImage})`}
      center
      className={containerStyles}
      maxWidth="md"
    >
      <Container center maxWidth={845} className={decorativeWrapperStyles}>
        <div className={classes.contentWrapper}>
          <Typography variant="h1" component="h1" className={classes.sectionHeading} gutterBottom>
            Before you go&#8230;
          </Typography>
          <Typography variant="h1" component="p" className={classes.sectionSubHeading} gutterBottom>
            We never want finances to be an obstacle.{' '}
          </Typography>
          <Typography variant="subtitle1" className={classes.bodyText} gutterBottom>
            If $180/year isn’t possible right now, choose a different amount to pay. Your
            subscription supports our mission to help people around the world fall in love with
            Torah. Please be as generous as you can.
          </Typography>

          <SubscribePlanSwitch onChange={setIsYearly} checked={isYearly} />
          <Typography variant="subtitle1" className={classes.iWishToPay}>
            I choose to pay:
          </Typography>
          <Box className={classes.inputWrapper}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <Input
                id="amount"
                className={classes.input}
                error={isAmountInvalid}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                endAdornment={<InputAdornment position="end">/month</InputAdornment>}
                inputProps={{
                  pattern: '^$(0|[1-9][0-9]{0,2})(,d{3})*(.d{1,2})?$'
                }}
                value={amount}
                onChange={e => setAmount(e)}
              />
              {isAmountInvalid && (
                <FormHelperText error id="amount-error">
                  Please input an amount between ${MIN_ALLOWED_AMOUNT} and ${MAX_ALLOWED_AMOUNT}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          {isYearly ? (
            <Typography variant="subtitle1" className={classes.billing}>
              billed annually
            </Typography>
          ) : (
            <Typography variant="subtitle1" className={classes.billing}>
              billed monthly
            </Typography>
          )}
          <JoinButton
            amount={amount}
            isYearly={isYearly}
            isAmountInvalid={isAmountInvalid}
            isPWYCAmount
            buttonText="Stay Premium"
          />
          <Typography variant="subtitle1" className={classes.bodyText} gutterBottom>
            Want to know what your contribution supports?{' '}
            <GoToSection to="Impact" smooth offset={0}>
              <span className={classes.gotoLink}> Click here.</span>
            </GoToSection>
          </Typography>
          <Typography
            variant="subtitle1"
            className={classes.gotoLink}
            gutterBottom
            onClick={handleCancel}
          >
            Proceed to cancel
          </Typography>
        </div>
      </Container>
    </Container>
  );
};

ChurnPaymentBlockVisuals.propTypes = {
  amount: PropTypes.string,
  setAmount: PropTypes.func.isRequired,
  isAmountInvalid: PropTypes.bool,
  isYearly: PropTypes.bool,
  setIsYearly: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired
};
ChurnPaymentBlockVisuals.defaultProps = {
  amount: String(PREMIUM_YEARLY_PRICE_PER_MONTH_USD),
  isAmountInvalid: false,
  isYearly: true
};

export default ChurnPaymentBlockVisuals;
