import React from 'react';
import PropTypes from 'prop-types';

import MuiButton from '@material-ui/core/Button';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: '4px',
    backgroundColor: '#458FCE',
    color: 'white',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '18.75px',
    padding: '10px 20px 11px 18px',
    display: 'block',
    margin: 'auto'
  },
  [theme.breakpoints.up('md')]: {
    button: {
      margin: 0,
      display: 'inline-block'
    }
  }
}));

const Button = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <MuiButton
      type="button"
      variant="contained"
      disableElevation
      className={classes.button}
      {...rest}
    >
      {children}
    </MuiButton>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired
};

export default Button;
