import { useEffect } from 'react';

import { useUserContext } from '~context/UserContext/UserSessionContext';

import * as ACTIONS from '~context/UserContext/actions/actions';
import useApi, {
  ACCOUNT_UPDATE_EMAIL,
  ACCOUNT_UPDATE_PASSWORD,
  ACCOUNT_UPDATE_PROFILE
} from '~hooks/useApi';
import { pushDataLayerEvent } from '~src/utils/data-layer';

export default () => {
  const { dispatch } = useUserContext();
  const [state, makeRequest] = useApi();
  const { isLoading, isError, data, error } = state;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isError && error) {
      return;
    }

    if (data) {
      dispatch({ type: ACTIONS.USER_READY, payload: data });
    }
  }, [data, error, isError, isLoading]); // eslint-disable-line

  return {
    state,
    updateEmail: ({ new_username, current_password }) => {
      const params = ACCOUNT_UPDATE_EMAIL({ new_username, current_password });
      makeRequest(params);
      pushDataLayerEvent('account_update_username');
    },
    updatePassword: ({ new_password }) => {
      const params = ACCOUNT_UPDATE_PASSWORD({ new_password });
      makeRequest(params);
      pushDataLayerEvent('account_update_password');
    },
    updateProfile: userMetaProps => {
      const params = ACCOUNT_UPDATE_PROFILE(userMetaProps);
      makeRequest(params);
      pushDataLayerEvent('account_update_profile_meta');
    }
  };
};
