import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import Link from '~components/Link';
import Divider from '~src/components/Divider';
import Header from './elements/Header';
import Subtitle from './elements/Subtitle';
import Button from './elements/Button';

const CounterOfferProcessingErrorScreen = ({ serverMessage }) => (
  <>
    <Header>
      We’re sorry but error happened while processing your request. Below is the error message
      returned from server.
    </Header>

    <Subtitle isImportant>{serverMessage}</Subtitle>

    <Subtitle>
      Please contact our customer support via email at
      <Link to="mailto:support@alephbeta.org">support@alephbeta.org</Link> or Intercom chat window
      in the corner of the screen.
    </Subtitle>

    <Divider />

    <Button onClick={() => navigate('/account')}>Manage Account</Button>
  </>
);

CounterOfferProcessingErrorScreen.propTypes = {
  serverMessage: PropTypes.string
};
CounterOfferProcessingErrorScreen.defaultProps = {
  serverMessage: '[No specific error message returned from server]'
};

export default CounterOfferProcessingErrorScreen;
