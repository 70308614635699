import React from 'react';
import PropTypes from 'prop-types';
import Divider from '~src/components/Divider';
import Header from './elements/Header';
import Subtitle from './elements/Subtitle';
import Button from './elements/Button';
import LinkButton from './elements/LinkButton';
import DiscountBanner from './elements/DiscountBanner';

const CounterOfferCustomerSupport = ({ handleAcceptOffer, handleDeclineOffer, isPayPalUser }) => (
  <>
    <Header>Can we help you?</Header>

    <Subtitle>
      We’re so sorry to hear you’ve been having technical difficulties. That’s so frustrating! Have
      you spoken to our Customer Happiness team? They can help sort out most issues and get you back
      to learning the Torah you love in a jiffy!
    </Subtitle>

    <DiscountBanner
      title="Don’t give up!"
      subtitle="Take 3 months at 50% off"
      button={<Button onClick={handleAcceptOffer}>Redeem Discount</Button>}
      hasPayPalNote={isPayPalUser}
    >
      We’re sorry you’re not using Aleph Beta as much as you’d like. Fitting Torah learning into
      your busy life can take time to figure out. Would you like to give it another three months at
      50% off to see if you can find your rhythm?
    </DiscountBanner>

    <Divider />

    <Button onClick={handleAcceptOffer}>Yes, Please! Redeem coupon!</Button>

    <LinkButton onClick={handleDeclineOffer}>Thanks, but I’d still like to cancel</LinkButton>
  </>
);

CounterOfferCustomerSupport.propTypes = {
  handleAcceptOffer: PropTypes.func.isRequired,
  handleDeclineOffer: PropTypes.func.isRequired,
  isPayPalUser: PropTypes.bool.isRequired
};

export default CounterOfferCustomerSupport;
