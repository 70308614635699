import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LoadingSpinner from '~src/components/LoadingSpinner';

const useStyles = makeStyles(theme => ({
  heading: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '28px',
    color: '#4B5161',
    marginBottom: '40px'
  },

  col_first_item: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#4B5161',
    marginBottom: '22px'
  },

  col_item: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#4B5161'
  },
  button_style: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    marginBottom: '16px',
    marginRight: '-8px',
    marginTop: '-6px'
  },
  studentsBox: {
    height: '550px',
    overflowY: 'scroll',
    [theme.breakpoints.down('md')]: {
      height: 'unset',
      overflowY: 'auto'
    }
  },
  align_right: {
    textAlign: 'right'
  }
}));

const PopupButton = ({ onButtonClick, id }) => {
  const classes = useStyles();
  const handleClick = () => {
    onButtonClick(id);
  };

  return (
    <Button className={classes.button_style} color="primary" onClick={handleClick}>
      Add Student
    </Button>
  );
};

PopupButton.propTypes = {
  id: PropTypes.number.isRequired,
  onButtonClick: PropTypes.func.isRequired
};

const InformationCard = ({ title, onButtonClick, students, isLoading, ...rest }) => {
  const classes = useStyles();

  return (
    <Box bgcolor="#fff" p={5} pb={2} borderBottom="1px solid rgba(0, 0, 0, 0.1)" {...rest}>
      <Grid container direction="row" justify="space-between" alignItems="flex-start">
        <Grid item xs={7}>
          <Typography className={classes.heading}>{title}</Typography>
        </Grid>
        <Grid item xs={5} className={classes.align_right}>
          <PopupButton title="Add Student" onButtonClick={onButtonClick} id={5} />
        </Grid>
        <Grid item xs={12} className={classes.studentsBox}>
          {isLoading && <LoadingSpinner textAlign="center" />}
          {students &&
            !isLoading &&
            students.map(student => (
              <Box bgcolor="#fff" p={3} border="2px solid #EFEFEF" className={classes.scrollStyle}>
                <Typography className={classes.col_first_item}>
                  {student.meta_first_name} {student.meta_last_name}{' '}
                </Typography>
                <Typography className={classes.col_item}> {student.username}</Typography>
              </Box>
            ))}
        </Grid>
      </Grid>
    </Box>
  );
};

InformationCard.propTypes = {
  title: PropTypes.string,
  students: PropTypes.arrayOf(
    PropTypes.shape({
      meta_first_name: PropTypes.string,
      meta_last_name: PropTypes.string,
      username: PropTypes.string.isRequired
    })
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired
};

InformationCard.defaultProps = {
  title: null
};

export default InformationCard;
