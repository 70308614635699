import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import {
  Impact,
  PaymentBlock,
  PremiumFeaturesHeader,
  Testimonials
} from '~src/modules/pay-what-you-can/components';
import ChurnPaymentBlockVisuals from '~modules/pay-what-you-can/components/PaymentBlock/ChurnPaymentBlockVisuals';

const AntiChurnPayWhatYouCan = ({ handleCancel }) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <PaymentBlock LayoutComponent={ChurnPaymentBlockVisuals} handleCancel={handleCancel} />
      <Testimonials underline />
      <Impact underline />
      <PremiumFeaturesHeader />
    </>
  );
};

AntiChurnPayWhatYouCan.propTypes = {
  handleCancel: propTypes.func.isRequired
};

export default AntiChurnPayWhatYouCan;
