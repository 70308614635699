import React from 'react';
import PropTypes from 'prop-types';
import Divider from '~src/components/Divider';
import Header from './elements/Header';
import LinkButton from './elements/LinkButton';
import Button from './elements/Button';
import DiscountBanner from './elements/DiscountBanner';

const CounterOfferDiscount = ({ handleAcceptOffer, handleDeclineOffer, isPayPalUser }) => (
  <>
    <Header>We hear you.</Header>

    <DiscountBanner
      title="You’re a valuable member of our community"
      subtitle="Does 50% off for 3 months help?"
      button={<Button onClick={handleAcceptOffer}>Redeem Discount</Button>}
      hasPayPalNote={isPayPalUser}
      hasUnemployedNote
    >
      Aleph Beta relies on subscriptions to create and share quality Torah content worldwide. But if
      you’re enjoying Aleph Beta, we’d hate for you to miss out because of finances. We’d love to
      offer you 3 months at 50% off.
    </DiscountBanner>

    <Divider />

    <LinkButton onClick={handleDeclineOffer}>Thanks, but I’d still like to cancel</LinkButton>
  </>
);

CounterOfferDiscount.propTypes = {
  isPayPalUser: PropTypes.bool,
  handleAcceptOffer: PropTypes.func.isRequired,
  handleDeclineOffer: PropTypes.func.isRequired
};
CounterOfferDiscount.defaultProps = {
  isPayPalUser: false
};

export default CounterOfferDiscount;
