import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextAreaInput } from '~src/components/form-inputs';
import Divider from '~src/components/Divider';
import Subtitle from './elements/Subtitle';
import Header from './elements/Header';
import Button from './elements/Button';
import LinkButton from './elements/LinkButton';
import DiscountBanner from './elements/DiscountBanner';

const FeedbackContents = ({ value, onChange, onSubmit }) => (
  <>
    <Header>We’d love to hear your feedback!</Header>

    <Subtitle>
      We’re sorry to see you go. Please take a minute to tell us a little more about what prompted
      this decision. Your feedback genuinely matters to us. We’re always looking to improve.{' '}
    </Subtitle>

    <TextAreaInput
      name="feedbackMessage"
      label="Please write your feedback here"
      value={value}
      onChange={onChange}
    />

    <Divider />
    <Button onClick={onSubmit}>Continue</Button>
  </>
);
FeedbackContents.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

const DecisionContents = ({ doAccept, doDecline, isPayPalUser }) => (
  <>
    <Header>Thank you for your feedback.</Header>
    <DiscountBanner
      title="You’re a valuable member of our community"
      subtitle="Explore for 3 months at 50% off"
      button={<Button onClick={doAccept}>Redeem Discount</Button>}
      hasPayPalNote={isPayPalUser}
      hasUnemployedNote
    >
      Why did you first join Aleph Beta? Were you looking to fit more Torah learning into your day?
      Maybe you heard Rabbi Fohrman teaching and got excited by his methodology. Or, perhaps,
      it&apos;s our colorful animation that caught your eye. We&apos;re sorry something&apos;s
      gotten in your way of late, but we hope 3 months at 50% off will inspire you to stick around
      and fall in love with Aleph Beta all over again.
    </DiscountBanner>
    <Divider />
    <LinkButton onClick={doDecline}>Thanks, but I’d still like to cancel</LinkButton>
  </>
);
DecisionContents.propTypes = {
  isPayPalUser: PropTypes.bool,
  doAccept: PropTypes.func.isRequired,
  doDecline: PropTypes.func.isRequired
};
DecisionContents.defaultProps = {
  isPayPalUser: false
};

const CounterOfferFeedback = ({ handleAcceptOffer, handleDeclineOffer, isPayPalUser }) => {
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [isEnteredMessage, setIsEnteredMessage] = useState(false);
  const doDecline = () => handleDeclineOffer({ payload: feedbackMessage });
  const doAccept = () => handleAcceptOffer({ payload: feedbackMessage });

  return (
    <>
      {isEnteredMessage ? (
        <DecisionContents isPayPalUser={isPayPalUser} doAccept={doAccept} doDecline={doDecline} />
      ) : (
        <FeedbackContents
          value={feedbackMessage}
          onChange={event => setFeedbackMessage(event.target.value)}
          onSubmit={() => setIsEnteredMessage(true)}
          handleDeclineOffer={doDecline}
        />
      )}
    </>
  );
};

CounterOfferFeedback.propTypes = {
  isPayPalUser: PropTypes.bool,
  handleAcceptOffer: PropTypes.func.isRequired,
  handleDeclineOffer: PropTypes.func.isRequired
};
CounterOfferFeedback.defaultProps = {
  isPayPalUser: false
};

export default CounterOfferFeedback;
