import React from 'react';
import { Router } from '@reach/router';

import makeStyles from '@material-ui/core/styles/makeStyles';

import Page from '~layout/Page';

import RestrictedRoute from '~containers/restricted-route';

import { ACCESS_LEVELS } from '~utils/required-subscription-plan';
import { AccountContainer } from '~modules/account';
import { CounterOffersContainer } from '~modules/anti-churn';

const useStyles = makeStyles({
  routerWrapper: {
    width: '100%'
  }
});

const Account = () => {
  const classes = useStyles();

  const minAccessLevel = ACCESS_LEVELS.LOGGED_IN;

  return (
    <Page>
      <Router className={classes.routerWrapper}>
        <RestrictedRoute
          minAccessLevel={minAccessLevel}
          path="/account"
          component={AccountContainer}
          default
          isAccount
        />
        <RestrictedRoute
          minAccessLevel={minAccessLevel}
          path="/account/billing/cancel"
          component={CounterOffersContainer}
          isAccount
        />
      </Router>
    </Page>
  );
};

export default Account;
