import useApi, {
  EDUCATION_GET_STUDENTS,
  EDUCATION_ADD_STUDENT,
  ACCOUNT_UPDATE_PROFILE
} from '~hooks/useApi';

export default () => {
  const [state, makeRequest] = useApi();

  return {
    state,
    getStudents: () => {
      const params = EDUCATION_GET_STUDENTS();
      makeRequest(params);
    },
    addStudent: ({ username, meta_first_name, meta_last_name }) => {
      const params = EDUCATION_ADD_STUDENT({ username, meta_first_name, meta_last_name });
      makeRequest(params);
    },
    updateSchoolName: ({ education_school_name }) => {
      const params = ACCOUNT_UPDATE_PROFILE({ education_school_name });
      makeRequest(params);
    }
  };
};
