import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import useEducation from '~hooks/useEducation';
import { AccountCta } from '~components/account';
import Container from '~components/Container';
import StudentsInfoCard from '../StudentsInfoCard';
import InformationCard from '../InformationCard';
import { calculateEducationData } from '../../account.utils';

const EducatorLayout = ({ user, handlePopupOpenClick, isEducator, addStudentState }) => {
  const { state, getStudents } = useEducation();
  const { data, isLoading } = addStudentState;
  const { data: studentsData } = state;

  useEffect(() => {
    getStudents();
  }, []);

  useEffect(() => {
    if (data) {
      getStudents();
    }
  }, [data]);

  if (!isEducator) {
    return (
      <Container center="horizontal" size={50} maxWidth="sm">
        <Box width="100%">
          <AccountCta
            title="Are you a teacher and interested in partnering with us to create a revolution in learning?"
            link="/education"
            linkText="Learn More"
            bgcolor="#fff"
          />
        </Box>
      </Container>
    );
  }
  return (
    <Grid container direction="row" justify="space-between" alignItems="flex-start">
      <Grid item xs={12} md={6} style={{ padding: '16px 16px 16px 0px' }}>
        <InformationCard
          title="Education Information"
          onButtonClick={handlePopupOpenClick}
          {...calculateEducationData(user)}
        />
      </Grid>
      <Grid item xs={12} md={6} style={{ padding: '16px 0px 16px 16px' }}>
        <StudentsInfoCard
          title="All Students"
          onButtonClick={handlePopupOpenClick}
          students={studentsData}
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  );
};

EducatorLayout.propTypes = {
  user: PropTypes.shape({}).isRequired,
  handlePopupOpenClick: PropTypes.func.isRequired,
  isEducator: PropTypes.bool.isRequired,
  addStudentState: PropTypes.shape({
    data: PropTypes.shape({}),
    isLoading: PropTypes.bool
  }).isRequired
};

export default EducatorLayout;
