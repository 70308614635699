import React from 'react';
import LoadingSpinner from '~src/components/LoadingSpinner';
import Subtitle from './elements/Subtitle';
import Header from './elements/Header';

const CounterOfferLoadingScreen = () => (
  <>
    <Header>Please wait until we process your request...</Header>

    <Subtitle>
      <LoadingSpinner />
    </Subtitle>
  </>
);

export default CounterOfferLoadingScreen;
