import React from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import useEducation from '~hooks/useEducation';

import MessageBox from '~components/forms/MessageBox';
import TextInput from '~components/form-inputs/TextInput';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import PopupCloseButton from '../shared/PopupCloseButton';
import PopupHeading from '../shared/PopupHeading';

const EducationInfoForm = ({ onClose }) => {
  const { register, handleSubmit } = useForm();
  const { session } = useUserContext();
  const { user } = session;
  const { state, updateSchoolName } = useEducation();
  const { isLoading, isError, data, error } = state;

  const onSubmit = ({ education_school_name }) => {
    updateSchoolName({ education_school_name });
    // FIXME: it doesn't automatically update the UI, because the school name is taken from the user's info, not the state of this API request.
  };

  // FIXME: it will fail with "cannot update AccountContainer while updating EducationInfoForm"
  if (data) {
    onClose();
  }

  return (
    <div style={{ position: 'relative' }}>
      <PopupHeading title="Edit Educator" />;
      <form onSubmit={handleSubmit(onSubmit)}>
        {isError && <MessageBox text={error} isError />}

        {!data && (
          <>
            <TextInput
              label="School Name"
              name="education_school_name"
              defaultValue={user.education_school_name}
              inputRef={register}
              disabled={isLoading}
              required
            />

            <ButtonSubmit fullWidth disabled={isLoading} style={{ boxShadow: 'unset' }}>
              Save Changes
            </ButtonSubmit>
          </>
        )}
      </form>
      <PopupCloseButton handleClose={onClose} />
    </div>
  );
};

EducationInfoForm.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default EducationInfoForm;
