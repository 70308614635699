/* eslint-disable no-console */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import request from '~utils/request';

import { pushDataLayerEvent } from '~utils/data-layer';
import { trackCancellationIntent } from '~utils/analytics';
import {
  CounterOfferSelectorScreen,
  CounterOfferLoadingScreen,
  CounterOfferProcessingErrorScreen,
  CounterOfferDiscountScreen,
  CounterOfferDiscountAcceptedScreen,
  CounterOfferAppScreen,
  CounterOfferAppAcceptedScreen,
  CounterOfferContentScreen,
  CounterOfferContentAcceptedScreen,
  CounterOfferCustomerSupportScreen,
  CounterOfferCustomerSupportAcceptedScreen,
  CounterOfferFeedbackScreen,
  CounterOfferFeedbackAcceptedScreen,
  CounterOfferFeedbackDiscountScreen,
  CounterOfferDeclinedScreen,
  CounterOfferMobileScreen
} from './screens';

const SCREENS = {
  LOADING: 999,
  ERROR: 666,
  OFFERS_SELECTOR: 1,
  OFFER_MOBILE: 753,
  OFFER_DISCOUNT: 500,
  OFFER_DISCOUNT_ACCEPTED: 501,
  OFFER_APP: 510,
  OFFER_APP_ACCEPTED: 511,
  OFFER_CONTENT: 520,
  OFFER_CONTENT_ACCEPTED: 521,
  OFFER_CUSTOMER_SUPPORT: 530,
  OFFER_CUSTOMER_SUPPORT_ACCEPTED: 531,
  OFFER_FEEDBACK: 540,
  OFFER_FEEDBACK_ACCEPTED: 541,
  OFFER_FEEDBACK_DISCOUNT: 542,
  DECLINED_OFFER: 3
};

const CounterOffersInterface = ({ isMobileUser, isPayPalUser }) => {
  const screenId = isMobileUser ? SCREENS.OFFER_MOBILE : SCREENS.OFFERS_SELECTOR;
  const [currentScreenId, setCurrentScreenId] = useState(screenId);
  const [serverMessage, setServerMessage] = useState('');

  // NOTE: order matters.
  const offers = {
    APP: {
      title: 'I don’t have time to watch Aleph Beta',
      code: 'app',
      screenId: SCREENS.OFFER_APP,
      acceptScreenId: SCREENS.OFFER_APP_ACCEPTED,
      declineScreenId: SCREENS.DECLINED_OFFER
    },
    CONTENT: {
      title: 'Your content isn’t for me',
      code: 'content',
      screenId: SCREENS.OFFER_CONTENT,
      acceptScreenId: SCREENS.OFFER_CONTENT_ACCEPTED,
      declineScreenId: SCREENS.DECLINED_OFFER
    },
    DISCOUNT: {
      title: 'My subscription is too expensive',
      code: 'discount',
      screenId: SCREENS.OFFER_DISCOUNT,
      acceptScreenId: SCREENS.OFFER_DISCOUNT_ACCEPTED,
      declineScreenId: SCREENS.DECLINED_OFFER
    },
    CUSTOMER_SUPPORT: {
      title: 'I’m having technical issues',
      code: 'customer-support',
      screenId: SCREENS.OFFER_CUSTOMER_SUPPORT,
      acceptScreenId: SCREENS.OFFER_CUSTOMER_SUPPORT_ACCEPTED,
      declineScreenId: SCREENS.DECLINED_OFFER
    },
    FEEDBACK: {
      title: 'Other',
      code: 'feedback',
      screenId: SCREENS.OFFER_FEEDBACK_DISCOUNT,
      acceptScreenId: SCREENS.OFFER_FEEDBACK_ACCEPTED,
      declineScreenId: SCREENS.DECLINED_OFFER
    }
  };

  const switchScreen = screen => {
    setCurrentScreenId(screen);
  };

  /**
   * Use this method to make the handler for any of "accept offer" and "decline offer" buttons.
   * @param {offerCode: string; responseLabel: string; screenId: number} config
   * Config is as follows:
   * * offerCode: special label identifying the offer at the backend. This ID would be sent to the backend to invoke the specified offer.
   * * responseLabel: either `accept` or `decline`. This identifies the endpoint to call at the backend.
   * * screenId: one of the SCREENS constants defined here to identify the screen to switch to after the offer processing ends.
   * @returns Function which is usable as a click handler. We hack the event handling mechanism to support passing arbitrary data to this handler.
   * If we pass any object as an argument value to that handler which has `payload` property that property value would be sent to backend alongside the offer reception request.
   */
  const makeOfferResponseHandler =
    ({ offerCode, responseLabel, passedScreenId }) =>
    event => {
      setCurrentScreenId(SCREENS.LOADING);
      request({
        url: `/cancellation-offers/${responseLabel}`,
        method: 'POST',
        data: {
          offer: offerCode,
          payload: event.payload
        }
      })
        .then(data => {
          if (responseLabel === 'decline') {
            trackCancellationIntent('confirmed');
            pushDataLayerEvent('subscription_cancel_confirm', { offerCode });
          } else {
            trackCancellationIntent('rejected');
            pushDataLayerEvent('subscription_cancel_offer_accepted', { offerCode });
          }

          setCurrentScreenId(passedScreenId);
          setServerMessage(data && data.data && data.data.message);
        })
        .catch(error => {
          console.error(error);
          setCurrentScreenId(SCREENS.ERROR_SCREEN);
          setServerMessage(error && error.message);
        });
    };

  const makeOfferAcceptHandler = ({ code, acceptScreenId }) =>
    makeOfferResponseHandler({
      offerCode: code,
      responseLabel: 'accept',
      passedScreenId: acceptScreenId
    });

  const makeOfferDeclineHandler = ({ code, declineScreenId }) =>
    makeOfferResponseHandler({
      offerCode: code,
      responseLabel: 'decline',
      passedScreenId: declineScreenId
    });

  switch (currentScreenId) {
    case SCREENS.LOADING:
      return <CounterOfferLoadingScreen />;
    case SCREENS.OFFERS_SELECTOR:
      return (
        <CounterOfferSelectorScreen
          offers={Object.values(offers)}
          handleSwitchScreen={screen => switchScreen(screen)}
        />
      );
    case SCREENS.OFFER_DISCOUNT:
      return (
        <CounterOfferDiscountScreen
          isPayPalUser={isPayPalUser}
          handleDeclineOffer={makeOfferDeclineHandler(offers.DISCOUNT)}
          handleAcceptOffer={makeOfferAcceptHandler(offers.DISCOUNT)}
        />
      );
    case SCREENS.OFFER_DISCOUNT_ACCEPTED:
      return <CounterOfferDiscountAcceptedScreen serverMessage={serverMessage} />;
    case SCREENS.OFFER_APP:
      return (
        <CounterOfferAppScreen
          isPayPalUser={isPayPalUser}
          handleDeclineOffer={makeOfferDeclineHandler(offers.APP)}
          handleAcceptOffer={makeOfferAcceptHandler(offers.APP)}
        />
      );
    case SCREENS.OFFER_APP_ACCEPTED:
      return <CounterOfferAppAcceptedScreen serverMessage={serverMessage} />;
    case SCREENS.OFFER_CONTENT:
      return (
        <CounterOfferContentScreen
          handleDeclineOffer={makeOfferDeclineHandler(offers.CONTENT)}
          handleAcceptOffer={makeOfferAcceptHandler(offers.CONTENT)}
        />
      );
    case SCREENS.OFFER_CONTENT_ACCEPTED:
      return <CounterOfferContentAcceptedScreen serverMessage={serverMessage} />;
    case SCREENS.OFFER_CUSTOMER_SUPPORT:
      return (
        <CounterOfferCustomerSupportScreen
          handleDeclineOffer={makeOfferDeclineHandler(offers.CUSTOMER_SUPPORT)}
          handleAcceptOffer={makeOfferAcceptHandler(offers.DISCOUNT)}
          isPayPalUser={isPayPalUser}
        />
      );
    case SCREENS.OFFER_CUSTOMER_SUPPORT_ACCEPTED:
      return <CounterOfferCustomerSupportAcceptedScreen serverMessage={serverMessage} />;
    case SCREENS.OFFER_FEEDBACK:
      return (
        <CounterOfferFeedbackScreen
          handleDeclineOffer={makeOfferDeclineHandler(offers.FEEDBACK)}
          handleAcceptOffer={makeOfferAcceptHandler(offers.FEEDBACK)}
        />
      );
    case SCREENS.OFFER_FEEDBACK_DISCOUNT:
      return (
        <CounterOfferFeedbackDiscountScreen
          isPayPalUser={isPayPalUser}
          handleDeclineOffer={() => switchScreen(SCREENS.OFFER_FEEDBACK)}
          handleAcceptOffer={makeOfferAcceptHandler(offers.DISCOUNT)}
        />
      );
    case SCREENS.OFFER_FEEDBACK_ACCEPTED:
      return (
        <CounterOfferFeedbackAcceptedScreen
          isPayPalUser={isPayPalUser}
          serverMessage={serverMessage}
        />
      );
    case SCREENS.DECLINED_OFFER:
      return <CounterOfferDeclinedScreen serverMessage={serverMessage} />;

    case SCREENS.OFFER_MOBILE:
      return <CounterOfferMobileScreen />;

    case SCREENS.ERROR:
    default:
      return <CounterOfferProcessingErrorScreen serverMessage={serverMessage} />;
  }
};

CounterOffersInterface.propTypes = {
  isPayPalUser: PropTypes.bool.isRequired,
  isMobileUser: PropTypes.bool.isRequired
};

export default CounterOffersInterface;
