import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  buttonStyle: {
    position: 'absolute',
    right: '-40px',
    top: '-40px'
  },
  iconStyle: {
    fontSize: '1.4rem',
    color: props => props.color || '#000000'
  }
});

const PopupCloseButton = ({ handleClose, color, ...rest }) => {
  const classes = useStyles({ color });
  return (
    <IconButton aria-label="close" className={classes.buttonStyle} onClick={handleClose} {...rest}>
      <CloseIcon className={classes.iconStyle} />
    </IconButton>
  );
};

PopupCloseButton.propTypes = {
  handleClose: PropTypes.func.isRequired,
  color: PropTypes.string
};
PopupCloseButton.defaultProps = {
  color: null
};

export default PopupCloseButton;
