import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import Divider from '~src/components/Divider';
import Header from './elements/Header';
import Subtitle from './elements/Subtitle';
import Button from './elements/Button';

const CounterOfferDeclinedScreen = ({ serverMessage = undefined }) => (
  <>
    <Header>We’re sorry to see you go.</Header>

    <Subtitle isImportant>Your membership has been canceled.</Subtitle>

    {serverMessage && <Subtitle isImportant>{serverMessage}</Subtitle>}

    <Divider />

    <Button onClick={() => navigate('/account')}>Manage Account</Button>
  </>
);

CounterOfferDeclinedScreen.propTypes = {
  serverMessage: PropTypes.string
};
CounterOfferDeclinedScreen.defaultProps = {
  serverMessage: undefined
};

export default CounterOfferDeclinedScreen;
