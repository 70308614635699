import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, navigate } from 'gatsby';

import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import unfurl from '~prismic/unfurl/producers-circle-content';
import ProducersCircleInformationCard from '../ProducersCircleInformationCard';
import ProducersCircleContentCard from '../ProducersCircleContentCard';

const useStyles = makeStyles(theme => ({
  left_col_padding: {
    padding: '16px 16px 16px 0px',
    [theme.breakpoints.down('md')]: {
      padding: '8px'
    }
  },
  right_col_padding: {
    padding: '16px 0px 16px 16px',
    [theme.breakpoints.down('md')]: {
      padding: '8px'
    }
  }
}));

const query = graphql`
  {
    prismicProducersCircleContent {
      data {
        body {
          ... on PrismicProducersCircleContentDataBodyUpcomingEvents {
            slice_type
            primary {
              title
              subtitle
            }
            items {
              event_title
              event_subtitle
              event_date
              event_link {
                url
              }
              link_display_text
            }
          }
          ... on PrismicProducersCircleContentDataBodyPastEvents {
            slice_type
            primary {
              title
              subtitle
            }
            items {
              event_title
              event_subtitle
              event_date
              event_link {
                url
                document {
                  ... on PrismicPlaylist {
                    data {
                      cover_image {
                        alt
                        url
                      }
                    }
                  }
                }
              }
              link_display_text
            }
          }
          ... on PrismicProducersCircleContentDataBodyContent {
            slice_type
            primary {
              title
              subtitle
            }
            items {
              material_title
              material_subtitle
              material_link {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const ProducersCircleLayout = ({ isUserStateLoading, isProducer }) => {
  const classes = useStyles();

  const { prismicProducersCircleContent } = useStaticQuery(query);
  const { upcomingEvents, pastEvents, content } = unfurl(prismicProducersCircleContent);

  useEffect(() => {
    if (isUserStateLoading) {
      return;
    }

    if (!isProducer) {
      navigate('/patrons-circle');
    }
  }, [isUserStateLoading, isProducer]);

  return (
    <Grid container direction="row" justify="space-between" alignItems="flex-start">
      <Grid item xs={12} md={6} className={classes.left_col_padding}>
        {upcomingEvents.map((event, index) => (
          <ProducersCircleInformationCard
            key={`${event.title} ${event.subtitle}`}
            isFirstCard={index === 0}
            {...event}
          />
        ))}
        {pastEvents.map(event => (
          <ProducersCircleInformationCard key={`${event.title} ${event.subtitle}`} {...event} />
        ))}
      </Grid>
      <Grid item xs={12} md={6} className={classes.right_col_padding}>
        <ProducersCircleContentCard {...content} />
      </Grid>
    </Grid>
  );
};

ProducersCircleLayout.propTypes = {
  isProducer: PropTypes.bool.isRequired,
  isUserStateLoading: PropTypes.bool.isRequired
};

export default ProducersCircleLayout;
