import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '~components/Divider';

import { useUserContext } from '~context/UserContext/UserSessionContext';

import AccountAvatar from '~components/AccountAvatar';

const useStyles = makeStyles(() => ({
  iconPosition: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const AccountSectionHeadingWithAvatar = ({ title }) => {
  const classes = useStyles();
  const { session } = useUserContext();
  const { user, isDonator, isProducer, fullName } = session;

  return (
    <Box pt={4}>
      <Typography variant="h5" component="h2" className={classes.iconPosition} gutterBottom>
        <AccountAvatar
          name={fullName || user.username}
          isDonator={isDonator}
          isProducer={isProducer}
          textSizeRatio={1.7}
          color="#438dce"
        />
        &nbsp; {title}
      </Typography>
      <Divider spacing={0} />
    </Box>
  );
};

AccountSectionHeadingWithAvatar.propTypes = {
  title: PropTypes.string.isRequired
};

export default AccountSectionHeadingWithAvatar;
