import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '~components/Divider';
import { producersCircleMaterialClick } from '~utils/analytics';
import { pushDataLayerEvent } from '~src/utils/data-layer';

const useStyles = makeStyles(theme => ({
  heading: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '28px',
    color: '#4B5161',
    marginBottom: '8px'
  },

  col_first_item: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#4B5161'
  },

  subtitle: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#4B5161'
  },

  studentsBox: {
    minHeight: '550px',
    overflowY: 'auto',
    scrollbarColor: '#C4C4C4 transparent',
    scrollbarWidth: 'thin',
    [theme.breakpoints.down('md')]: {
      height: 'unset',
      overflowY: 'auto'
    },
    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '15px',
      backgroundColor: '#C4C4C4'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      borderRadius: '10px'
    }
  },
  align_right: {
    textAlign: 'right'
  },
  root: {
    marginTop: '8px',
    marginBottom: '16px'
  }
}));

const ProducersCircleContentCard = ({ title, subtitle, materials, ...rest }) => {
  const classes = useStyles();

  const handleLinkClick = (materialLink, materialTitle) => {
    producersCircleMaterialClick(materialLink, materialTitle);

    pushDataLayerEvent('producers_circle_material_download', { materialLink, materialTitle });

    window.open(materialLink, '_blank');
  };

  return (
    <Box bgcolor="#fff" p={5} pb={2} borderBottom="1px solid rgba(0, 0, 0, 0.1)" {...rest}>
      <Grid container direction="row" justify="space-between" alignItems="flex-start">
        <Grid item xs={12}>
          <Typography className={classes.heading}>{title}</Typography>
          <Typography className={classes.subtitle}>{subtitle}</Typography>
        </Grid>
        <Divider spacing={3} />
        <Grid
          item
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          xs={12}
          className={classes.studentsBox}
        >
          {materials &&
            materials.map(material => (
              <Box
                key={material.material_link.url}
                component={Grid}
                item
                container
                direction="row"
                justify="space-around"
                alignItems="center"
                width="100%"
                bgcolor="#fff"
                border="2px solid #EFEFEF"
                p={3}
                classes={{
                  root: classes.root
                }}
              >
                <Grid item xs={12} md={8}>
                  <Typography className={classes.col_first_item} gutterBottom>
                    {material.material_title}
                  </Typography>
                  <Typography className={classes.subtitle}>{material.material_subtitle}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button
                    variant="text"
                    color="primary"
                    underline="none"
                    onClick={() =>
                      handleLinkClick(material.material_link.url, material.material_title)
                    }
                    fullWidth
                  >
                    Download
                  </Button>
                </Grid>
              </Box>
            ))}
        </Grid>
      </Grid>
    </Box>
  );
};

ProducersCircleContentCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  materials: PropTypes.arrayOf(
    PropTypes.shape({
      material_title: PropTypes.string,
      material_subtitle: PropTypes.string,
      material_link: PropTypes.shape({
        url: PropTypes.string
      })
    })
  ).isRequired
};

ProducersCircleContentCard.defaultProps = {
  title: null,
  subtitle: null
};

export default ProducersCircleContentCard;
