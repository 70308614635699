import React from 'react';

import { Link as GatsbyLink } from 'gatsby';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import { useUserContext } from '~context/UserContext/UserSessionContext';

import AccessLevelLabel from '~components/access-level-label/AccessLevelLabel';

const useStyles = makeStyles({
  spanFontWeight: {
    fontWeight: 600
  }
});

const handleUpgradeLink = (access_level: number | string) => {
  switch (access_level) {
    case 3:
    case '3':
      return '/patrons-circle';
    default:
      return '/subscribe';
  }
};

const AccountInformation = () => {
  const classes = useStyles();
  const { session } = useUserContext();
  const { user, fullName } = session;
  const { username, is_admin, access_level } = user;

  const upgradeLink = handleUpgradeLink(access_level);

  const isProducer = access_level === 4;

  return (
    <Box pt={2}>
      <Typography variant="subtitle1" gutterBottom>
        Username: <span className={classes.spanFontWeight}>{username}</span>
      </Typography>

      {fullName && (
        <Typography variant="subtitle1" gutterBottom>
          Full Name: <span className={classes.spanFontWeight}>{fullName}</span>
        </Typography>
      )}

      <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
        <Grid item xs="auto">
          <Typography variant="subtitle1">Access Level:</Typography>
        </Grid>

        <Grid item xs={6} sm={4}>
          <AccessLevelLabel access_level={access_level} is_admin={is_admin} />
        </Grid>
        {!isProducer && (
          <Grid item xs={6} sm={4}>
            <Button
              component={GatsbyLink}
              to={upgradeLink}
              variant="contained"
              color="primary"
              size="small"
              fullWidth
            >
              Upgrade
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default AccountInformation;
