import React from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import Button from '@material-ui/core/Button';
import { useUserContext } from '~context/UserContext/UserSessionContext';

import useAccount from '~hooks/useAccount';

import MessageBox from '~components/forms/MessageBox';
import TextInput from '~components/form-inputs/TextInput';
import PasswordInput from '~components/form-inputs/PasswordInput';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import PopupCloseButton from '../shared/PopupCloseButton';
import PopupHeading from '../shared/PopupHeading';

const EmailForm = ({ onClose }) => {
  const { register, handleSubmit } = useForm();
  const { session } = useUserContext();
  const { user } = session;

  const { state, updateEmail } = useAccount();
  const { isLoading, isError, data, error } = state;

  const onSubmit = ({ current_password, new_username }) => {
    updateEmail({ current_password, new_username });
  };
  if (data) {
    onClose();
  }

  return (
    <div style={{ position: 'relative' }}>
      <PopupHeading title="Change Email" />

      <form onSubmit={handleSubmit(onSubmit)}>
        {isError && <MessageBox text={error} isError />}

        {!data && (
          <>
            <TextInput
              label="Email"
              name="new_username"
              type="email"
              defaultValue={user.username}
              inputRef={register}
              disabled={isLoading}
              required
            />

            <PasswordInput
              label="Current Password"
              name="current_password"
              inputRef={register}
              disabled={isLoading}
            />

            <ButtonSubmit fullWidth disabled={isLoading} style={{ boxShadow: 'unset' }}>
              Save Changes
            </ButtonSubmit>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={onClose}
              disabled={isLoading}
              style={{ boxShadow: 'unset' }}
            >
              Cancel
            </Button>
          </>
        )}
      </form>
      <PopupCloseButton handleClose={onClose} />
    </div>
  );
};

EmailForm.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default EmailForm;
