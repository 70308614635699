import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import Divider from '~src/components/Divider';
import Header from './elements/Header';
import Button from './elements/Button';
import LinkButton from './elements/LinkButton';
import DiscountBanner from './elements/DiscountBanner';
import MobileAppsBanner from './elements/MobileAppsBanner';

const CounterOfferApp = ({ handleAcceptOffer, handleDeclineOffer, isPayPalUser }) => (
  <>
    <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
      <Grid item xs={12} md={7}>
        <Header>
          We hear you. Did you know we have a mobile app that gives you access to our content on the
          go?
        </Header>
        <DiscountBanner
          title="Don’t give up!"
          subtitle="Take 3 months at 50% off"
          button={<Button onClick={handleAcceptOffer}>Redeem Discount</Button>}
          hasPayPalNote={isPayPalUser}
        >
          We’re sorry you’re not using Aleph Beta as much as you’d like. Fitting Torah learning into
          your busy life can take time to figure out. Would you like to give it another three months
          at 50% off to see if you can find your rhythm?
        </DiscountBanner>
      </Grid>
      <Grid item xs={12} md={5}>
        <div
          style={{
            marginTop: '85px',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center'
          }}
        >
          <div
            style={{
              maxWidth: '374px',
              border: '1px solid rgba(75,81,97,0.2)',
              padding: '44px 37px 43px 38px'
            }}
          >
            <MobileAppsBanner>
              Download our app and squeeze 20 minutes of weekly Parsha wisdom into your day.
            </MobileAppsBanner>
          </div>
        </div>
      </Grid>
    </Grid>

    <Divider />

    <LinkButton onClick={handleDeclineOffer}>Thanks, but I’d still like to cancel</LinkButton>
  </>
);
CounterOfferApp.propTypes = {
  isPayPalUser: PropTypes.bool,
  handleAcceptOffer: PropTypes.func.isRequired,
  handleDeclineOffer: PropTypes.func.isRequired
};
CounterOfferApp.defaultProps = {
  isPayPalUser: false
};

export default CounterOfferApp;
