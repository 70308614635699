import React from 'react';
import PropTypes from 'prop-types';
import Divider from '~src/components/Divider';
import Header from './elements/Header';
import LinkButton from './elements/LinkButton';
import Button from './elements/Button';
import DiscountBanner from './elements/DiscountBanner';

const CounterOfferFeedbackDiscount = ({ isPayPalUser, handleAcceptOffer, handleDeclineOffer }) => (
  <>
    <Header>We hear you.</Header>

    <DiscountBanner
      title="Don't give up"
      subtitle="Take 3 months at 50% off"
      button={<Button onClick={handleAcceptOffer}>Redeem Discount</Button>}
      hasPayPalNote={isPayPalUser}
    >
      We’re sorry you’re not using Aleph Beta as much as you’d like. Fitting Torah learning into you
      busy life can take time to figure out. Would you like to give it another three months at 50%
      off to see if you can find your rhythm?
    </DiscountBanner>

    <Divider />

    <LinkButton onClick={handleDeclineOffer}>Thanks, but I’d still like to cancel</LinkButton>
  </>
);

CounterOfferFeedbackDiscount.propTypes = {
  isPayPalUser: PropTypes.bool,
  handleAcceptOffer: PropTypes.func.isRequired,
  handleDeclineOffer: PropTypes.func.isRequired
};
CounterOfferFeedbackDiscount.defaultProps = {
  isPayPalUser: false
};

export default CounterOfferFeedbackDiscount;
